<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.799805 4L15.1998 4" stroke="currentColor" stroke-width="1.5"/>
        <path d="M6.39941 7.2002V12.0002" stroke="currentColor" stroke-width="1.5"/>
        <path d="M9.59961 7.2002V12.0002" stroke="currentColor" stroke-width="1.5"/>
        <path d="M2.39941 3.99951L3.19941 13.5995C3.19941 14.0238 3.36798 14.4308 3.66804 14.7308C3.96809 15.0309 4.37506 15.1995 4.7994 15.1995H11.1994C11.6237 15.1995 12.0307 15.0309 12.3307 14.7308C12.6308 14.4308 12.7994 14.0238 12.7994 13.5995L13.5994 3.99951" stroke="currentColor" stroke-width="1.5"/>
        <path d="M5.59961 3.99979V1.5998C5.59961 1.38763 5.68389 1.18415 5.83392 1.03412C5.98395 0.88409 6.18743 0.799805 6.39961 0.799805H9.59959C9.81176 0.799805 10.0152 0.88409 10.1653 1.03412C10.3153 1.18415 10.3996 1.38763 10.3996 1.5998V3.99979" stroke="currentColor" stroke-width="1.5"/>
    </svg>
</template>

<script>
export default {
    name: 'IconTrash'
}
</script>

<style scoped>

</style>