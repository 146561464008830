
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { IDayData } from "@/types/api";


    @Component

    export default class TooltipVacation extends Vue {
        @Prop({ required: true })
        private dayData!: IDayData;

        get taskId(){
            return this.dayData.vacation
                ? this.dayData.vacation.id :
                this.dayData.sickDay
                    ? this.dayData.sickDay.id : ''

        }
        get statusName() {
            return this.dayData.vacation
                ? this.dayData.vacation.status.name :
                this.dayData.sickDay
                    ? this.dayData.sickDay.status.name : ''
        }
        get usePeopleForce(){
            return process.env.VUE_APP_DEFAULT_VACATION_SOURCE == 'peopleForce' ? true : false;
        }

    }
