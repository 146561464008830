
    import { Component, Vue } from "vue-property-decorator";
    import { NavTab } from '@/components';

    @Component({
        components: { NavTab }
    })
    export default class Report extends Vue {

        get currentUser() {
            return this.$store.state.currentUser
        }
    }
