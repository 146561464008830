
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class ProjectsInfoTablePosition extends Vue {
  @Prop({ required: true })
  public position!: string;

  @Prop({ required: true })
  public totalDescription!: string;
}
