import Vue from 'vue'
import Vuex from 'vuex'
//import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import config from '@/config';

const { initialPosition } = config;

Vue.use(Vuex)

const state = {
    currentUser: null,
    initialDataFetched: false,

    benchId: 34,

    timeTableView: { name: "Month", value: "month" },
    timeTableMonth: new Date().getMonth(),
    timeTableYear: new Date().getFullYear(),

    // workloadPlanner: null,
    teamStatus: null,

    activePositions: [initialPosition],

    lastReloadFromNow: "now",
    shortCacheUpdatedAt:  "",
    longCacheUpdatedAt:  "",
    teamCacheUpdatedAt:  "",
    teamPositionsCacheUpdatedAt:  "",

    appDataLoading: false,
    appDataLoadedSuccess: null,
    appDataLoadedError: null,
    currentWeekUser: null,
    appDataUnauthorized: null,

    typingComment: false
}

export default new Vuex.Store({
    state,
    //getters,
    mutations,
    actions,
})
