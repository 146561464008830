
    import { Vue, Component } from "vue-property-decorator";
    
@Component

export default class NavTab extends Vue {

    get currentUser() {
        return this.$store.state.currentUser
    }
}

