var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"table-week__button",class:{
    'table-week--full': _vm.isPlanFull,
    'table-week--partial': _vm.holidayCount > 0
  }},[_c('span',{staticClass:"table-week__layers"},[_c('span',{staticClass:"table-week__layer table-week__layer--vacations"},[_c('span',{staticClass:"table-week__layer-bg",style:({ transform: `scaleY(${_vm.vacationsAndSickDaysPercent})` })})]),_c('span',{staticClass:"table-week__layer table-week__layer--plan",style:({ ..._vm.time.planStyle })}),_c('span',{staticClass:"table-week__layer table-week__layer--thin table-week__layer--fact",style:({ transform: `scaleY(${_vm.factPercent})`, backgroundColor: _vm.getFactBGC(_vm.totalPercent) })}),_vm._l((_vm.nonBillableWithSpan),function(nonBillableItem){return _c('span',{key:nonBillableItem.nonBillable.color,staticClass:"table-week__layer table-week__layer--thin table-week__layer--bench",style:({
          transform: `scaleY(${nonBillableItem.nonBillable.percent / 100})`,
          top: `${nonBillableItem.start}%`,
          backgroundColor: nonBillableItem.nonBillable.color
        })})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }