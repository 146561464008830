
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class ProjectsInfoTableHours extends Vue {
  @Prop({ required: true })
  public time!: string | null;

  @Prop({ required: true })
  public background!: string | null;
}
