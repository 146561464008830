import dayjs from "dayjs";
import config from "@/config";
import {IWorloadPlanner} from "@/types/api";

export function sortWorkloadPlanner(a: IWorloadPlanner, b: IWorloadPlanner) {
    if (a.workload === 'no info' && parseInt(b.workload)) {
        return 1
    } else if (b.workload === 'no info' && parseInt(a.workload)) {
        return -1
    } else if (parseInt(a.workload) >= parseInt(b.workload)) {
        return -1
    } else {
        return 0
    }
}

export const NO_INFO_WORKLOAD = "no info";
export const FREE_WORKLOAD = "free";
export const SUPPORT_WORKLOAD = "support";

export function getWorkloadPlannerTotalScore(
    scores: IWorloadPlanner[]
): number {
    if (!scores) return 0;

    return scores
        .map((s) => s.workload)
        .filter((s) => s !== NO_INFO_WORKLOAD && s !== FREE_WORKLOAD)
        .map((s) => {
            if (s === SUPPORT_WORKLOAD) return 10;
            return parseInt(s.replace(/[^\d.-]/g, "")) || 0;
        })
        .reduce((el, acc) => {
            return acc + el;
        }, 0);
}

export const isProjectWithoutInfo = (porject: IWorloadPlanner) =>
    porject.workload === NO_INFO_WORKLOAD;

export function hasAnyNoInfoProjects(projects: IWorloadPlanner[]): boolean {
    if (!projects) return false;

    return projects.some(isProjectWithoutInfo);
}

export function getDayColumnStyles(
    duration: string | null,
    totalPercent: number | null,
    nonBillable: Record<string, { percentsFromWorkingDay: number | null, percentsFromTotalTime: number | null }>,
    mode: 'month' | 'quarter' = 'month'
): { [key: string]: string } {
    const { workloadStyles } = config;

    if (
        duration === null ||
        totalPercent === null ||
        !nonBillable
    )
        return {};


    let finalPercent = 0
    const nonBillableKeyframes: {start: number, end: number, color: string}[] = []
    let nonBillablePercent = 0

    for (const nonBillableColor in nonBillable) {
        const { percentsFromWorkingDay, percentsFromTotalTime } = nonBillable[nonBillableColor]

        if (percentsFromTotalTime !== null && percentsFromWorkingDay !== null) {
            const newGradientObj = {
                start: finalPercent,
                end: totalPercent > 100
                    ? finalPercent + percentsFromTotalTime
                    : finalPercent + percentsFromWorkingDay,
                color: nonBillableColor
            };

            nonBillablePercent += Math.abs(newGradientObj.end - newGradientObj.start)
            nonBillableKeyframes.push(newGradientObj)
            finalPercent =  newGradientObj.end
        }
    }
    let nonBillableGradient = nonBillableKeyframes.reduce((acc, curr) => {
        if (totalPercent > 100) {
            acc += `${curr.color} ${Math.abs(curr.start)}% ${Math.abs(curr.end)}%,`
        } else {
            acc += `${curr.color} ${curr.start + Math.abs(totalPercent - nonBillablePercent)}% ${curr.end + Math.abs(totalPercent - nonBillablePercent)}%,`
        }
        return acc
    }, '')
    nonBillableGradient = nonBillableGradient.slice(0, nonBillableGradient.length - 1).trim()

    const [hours, minutes] = duration.split(":");
    const time = dayjs()
        .hour(hours as any)
        .minute(minutes as any);

    const fiveDuration = mode === 'month' ? dayjs().hour(6).minute(0) : dayjs().hour(30).minute(0);
    const eightDuration = mode === 'month' ? dayjs().hour(7).minute(30) : dayjs().hour(40).minute(0);
    const nineDuration = mode === 'month' ? dayjs().hour(9).minute(30) : dayjs().hour(48).minute(0);
    const twelveDuration = mode === 'month' ? dayjs().hour(12).minute(0) : dayjs().hour(60).minute(0);

    let color = workloadStyles["--color-secondary"];
    let rgba = workloadStyles["--total-time-tracked-100"];

    if (time <= fiveDuration) {
        rgba = workloadStyles["--total-time-tracked-low"];
    } else if (time <= eightDuration) {
        rgba = workloadStyles["--total-time-tracked-50"];
    } else if (time >= twelveDuration) {
        color = workloadStyles["--color-primary"];
        rgba = workloadStyles["--total-time-tracked-150"];
    } else if (time >= nineDuration) {
        color = workloadStyles["--color-primary"];
        rgba = workloadStyles["--total-time-tracked-125"];
    }

    let finalBGI = ''
    if (nonBillableGradient && nonBillableGradient.length > 0) {
        if (totalPercent > 100) {
            finalBGI = `linear-gradient(to bottom, ${nonBillableGradient}, transparent ${finalPercent}% 0%), linear-gradient(to top, ${rgba} 0% 100%, transparent 100% 100%)`
        } else {
            finalBGI = `linear-gradient(to top, ${rgba} 0% ${totalPercent - nonBillablePercent}%, transparent ${totalPercent - nonBillablePercent}% 100%), linear-gradient(to top, ${nonBillableGradient}, transparent ${finalPercent - nonBillablePercent}% 100%)`
        }
    } else {
        finalBGI = `linear-gradient(to top, ${rgba} 0% ${totalPercent}%, transparent ${totalPercent}% 0%)`
    }

    return {
        "background-image": finalBGI,
        "background-size": `100% 100%`,
        color: color,
    };
}

export const splitSelectedWeeks = <T extends []>(array: T[], index: number) => {
    if (array.length) {
        const leftPartArray = array.slice(0, index + 1);
        const rightPartArray = array.slice(index, array.length);
        if (leftPartArray.length < rightPartArray.length) {
            return rightPartArray.slice(0, array.length);
        }
        if (leftPartArray.length > rightPartArray.length) {
            return leftPartArray.slice(0, index + 1);
        }
        if (leftPartArray.length === rightPartArray.length) {
            return leftPartArray;
        }
    }
    return [];
}
