
    import { Component, Prop, Vue } from "vue-property-decorator";

    import {
        BA,
        Backend,
        Content,
        Copywriter,
        CSO,
        Designer,
        DevOps,
        Frontend,
        HR,
        MobileDev,
        OfficeManager,
        Other,
        PM,
        QA,
        Recruiter,
        Remote,
        Sales,
        SEO,
    } from '@/icons';

    @Component({
        components: {
            BA,
            Backend,
            Content,
            Copywriter,
            CSO,
            Designer,
            DevOps,
            Frontend,
            HR,
            MobileDev,
            OfficeManager,
            Other,
            PM,
            QA,
            Recruiter,
            Remote,
            Sales,
            SEO,
        }
    })

    export default class TableContentPosition extends Vue {
        @Prop({ required: true })
        private position!: string;
    }

