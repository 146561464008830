<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="check">
            <path id="Icon" d="M7.80001 2.90015L3.95001 6.75015L2.20001 5.00015" stroke="black" stroke-width="1.2"
                stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconCheck'
}
</script>

<style scoped></style>