
import { Vue, Component, Ref, Prop, Emit } from 'vue-property-decorator';
@Component
export default class SelectSearch extends Vue {
    private isOpen: boolean = false;
    @Ref('refInput') readonly refInput;

    @Prop({ required: true })
    private value!: string;

    @Prop({ default: false})
    private isDisabled!: boolean;

    @Prop()
    private label!: string;

    @Prop({ required: true })
    private options!: string[];

    @Emit('input')
    onInput(option) {
        this.isOpen = !this.isOpen;
        return option;
    }

    get selectOptions() {
        return this.options;
    }

    get selectedOption() {
        return this.value
    }

    get isDisabledSelect() {
        return this.isDisabled;
    }

    onOpenSelect() {
        if (this.isDisabledSelect) {
            return;
        }
        if (!this.isOpen) {
            this.isOpen = !this.isOpen;
            this.$nextTick(() => {
                this.refInput.focus();
            })
            return
        } else {
            this.isOpen = false;
        }
    }

    onCloseDropDown() {
        setTimeout(() => {
            this.isOpen = false;
        }, 125)
    }

    onOpenDropDown(event) {
        this.isOpen = true;
    }

}
