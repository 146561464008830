import { apiService } from "@/services/ApiService";
import { reportService } from "@/services/ReportService";
import { IWeekTime } from "@/types/api";

/* loading */

const loadingStart = {
    type: "HANDLE_APP_DATA_LOADING",
    payload: {
        loading: true,
        loaded: false,
        error: false,
        unauthorized: false,
    }
}

const loadingSuccess = {
    type: "HANDLE_APP_DATA_LOADING",
    payload: {
        loading: false,
        loaded: true,
        error: false,
        unauthorized: false,
    }
}

const loadingError = {
    type: "HANDLE_APP_DATA_LOADING",
    payload: {
        loading: false,
        loaded: false,
        error: true,
        unauthorized: false
    }
}

const unauthorizedError = {
    type: "HANDLE_APP_DATA_LOADING",
    payload: {
        unauthorized: true
    }
}

/* apiService */

const PerformServiceRequest = async (commit, request, flag = false) => {

    if (!flag) {
        commit(loadingStart)
    }

    try {
        const data = await request()
        commit(loadingSuccess)
        return data
    }
    catch (e) {
        if (e.message.includes('401')) {
            commit(unauthorizedError)
        }
        commit(loadingError)
    }
}

export const GetCurrentUser = async ({ commit }) => {
    const currentUser = await PerformServiceRequest(commit, () => apiService.getCurrentUser());
    //const currentUser = null
    commit({
        type: "SET_STORE_FIELDS",
        payload: {
            field: ['currentUser'],
            value: [currentUser],
        }
    })

    return currentUser
}

export const GetAccessToken = async ({ commit, state }, { query }) => {
    return await PerformServiceRequest(commit, () => apiService.getAccessToken(query))
}

export const GetTimes = async ({ commit, state }, { startDate, endDate, positions, removeLoaderFlag }) => {
    return await PerformServiceRequest(commit, () => apiService.getTimes(startDate, endDate, positions), removeLoaderFlag)
}

export const GetTimesCurrent = async ({ commit, state }, { removeLoaderFlag }) => {
    return await PerformServiceRequest(commit, () => apiService.getTimesCurrent(), removeLoaderFlag)
}

export const GetTimesChart = async ({ commit, state }, { projectIds, userIds, removeLoaderFlag }) => {
    return await PerformServiceRequest(commit, () => apiService.getTimesChart(projectIds, userIds), removeLoaderFlag)
}

export const GetWeekTimes = async ({ commit }, { month, year, offset, positions, removeLoaderFlag }): Promise<IWeekTime[]> => {
    return await PerformServiceRequest(commit, () => apiService.getWeekTimes(month, year, offset, positions), removeLoaderFlag)
}

export const GetTeam = async ({ commit, state }, {removeLoaderFlag}) => {
    return await PerformServiceRequest(commit, () => apiService.getTeam(), removeLoaderFlag)
}

export const GetVacations = async ({ commit, state }, {removeLoaderFlag}) => {
    return await PerformServiceRequest(commit, () => apiService.getVacations(), removeLoaderFlag)
}

export const GetSickDays = async ({ commit, state }, {removeLoaderFlag}) => {
    return await PerformServiceRequest(commit, () => apiService.getSickDays(), removeLoaderFlag)
}

export const GetHolidays = async ({ commit, state }) => {
    return await PerformServiceRequest(commit, () => apiService.getHolidays())
}

export const GetWorkdays = async ({commit, state}) => {
    return await PerformServiceRequest(commit, () => apiService.getWorkdays())
}

export const GetRates = async ({ commit, state }) => {
    return await PerformServiceRequest(commit, () => apiService.getRates())
}

export const GetPositions = async ({ commit, state }) => {
    return await PerformServiceRequest(commit, () => apiService.getPositions())
}

export const GetSkills = async ({ commit, state }) => {
    return await PerformServiceRequest(commit, () => apiService.getSkills())
}

/* note day comments */

export const GetComments = async ({ commit, state }, { startDate, endDate, removeLoaderFlag }) => {
    return await PerformServiceRequest(commit, () => apiService.getComments(startDate, endDate), removeLoaderFlag)
}

export const SaveComment = async ({ commit, state }, { input }) => {
    return await PerformServiceRequest(commit, () => apiService.saveComment(input))
}

export const DeleteComment = async ({ commit, state }, { id }) => {
    return await PerformServiceRequest(commit, () => apiService.deleteComment(id))
}


/* reportService */

export const GenerateReport = async ({ commit, state }, { selectedTasks, selectedUsers }) => {
    return await PerformServiceRequest(commit, () => reportService.generate(selectedTasks, selectedUsers))
}

export const SaveRates = async ({ commit, state }, { reportData }) => {
    return await PerformServiceRequest(commit, () => reportService.saveRates(reportData))
}

export const SaveToGoogleSheets = async ({ commit, state }, { reportData, sheetName, startDate, endDate, rateMembers }) => {
    return await PerformServiceRequest(commit, () => reportService.saveToGoogleSheets(reportData, sheetName, startDate, endDate, rateMembers))
}

export const GetReport = async ({ commit, state }, { reportData }) => {
    return await PerformServiceRequest(commit, () => reportService.getReport(reportData))
}

export const GetReportTimes = async ({ commit, state }, { startDate, endDate }) => {
    return await PerformServiceRequest(commit, () => reportService.getReportTimes(startDate, endDate))
}
// remove loading planning from excel for Brights
// export const AccessWorkloadPlannerDataAsync = async ({ commit }) => {
//     const workloadPlanner = await reportService.accessWorkloadPlannerDataAsync()
//     commit({
//         type: "SET_STORE_FIELDS",
//         payload: {
//             field: ['workloadPlanner'],
//             value: [workloadPlanner],
//         }
//     })
// }

export const AccessTeamStatusDataAsync = async ({ commit }) => {
    const teamStatus = await reportService.accessTeamStatusDataAsync()
    commit({
        type: "SET_STORE_FIELDS",
        payload: {
            field: ['teamStatus'],
            value: [teamStatus],
        }
    })
}

/* Week info service */

export const SaveProject = async ({ commit }, project) => {
    return await PerformServiceRequest((commit), () => apiService.saveProject(project), true);
}

export const RemoveProject = async ({ commit }, planIds) => {
    return await PerformServiceRequest((commit), () => apiService.removeProject(planIds), true);
}

export const GetAllProjects = async ({ commit }) => {
    return await PerformServiceRequest(commit, () => apiService.getAllProjects());
}

export const GetTimesPlanning = async ({ commit }, params) => {
    return await PerformServiceRequest(commit, () => apiService.getTimesPlanning(params));
}