<template>
  <LineChartGenerator
    ref="chart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({
        // minWidth: "2551px",
        height: "350px",
        maxHeight: "350px",
        overflowY: "hidden",
      }),
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.data,
      },
      chartOptions: {
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
          z: 9999,
        },
        scaleShowLabels: false,
        animantion: false,
        elements: {
          point: {
            radius: 2,
          },
        },
        layout: {
          padding: {
            bottom: 20,
          },
        },
        plugins: {
          tooltip: {
            z: 9999,
            callbacks: {
              title: (context) => {
                const sum = Object.entries(
                  context[0].parsed["_stacks"].y
                ).reduce((acc, [key, curr]) => {
                  if (+key || key === "0") {
                    acc += +curr;
                    return acc;
                  }
                  return acc;
                }, 0);
                return `${context[0].label}, Sum: ${sum}`;
              },
              label: function (context) {
                if (context.dataset.yAxisID === "y") {
                  return "";
                }
                if (context.parsed.y === 0) return "";
                return `${context.dataset.label}: ${context.parsed.y}`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              categorySpacing: 10,
              barPercentage: 20,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            stacked: true,
            type: "linear",
            display: true,
            position: "left",
          },
          y1: {
            display: true,
            stacked: true,
            position: "right",
            grid: {
              drawOnChartArea: false,
            },
          },
        },
        tension: 0,
      },
    };
  },
  watch: {
    data: {
      handler(data) {
        this.$set(this.chartData, "datasets", [
          ...data.map((it) => ({ ...it, yAxisID: "y1" })),
          ...data.map((it) => ({
            ...it,
            yAxisID: "y",
            backgroundColor: "transparent",
            borderColor: "transparent",
          })),
        ]);
        this.updateChart();
      },
      immediate: true,
    },
    labels: {
      handler(labels) {
        const additionalLength = 29;
        const additionalLabels = additionalLength - labels.length;
        const withEmptyLabels =
          labels.length < additionalLabels
            ? labels.concat(new Array(additionalLabels).fill(null))
            : labels;
        const gap = `${withEmptyLabels.length * 10.8}px`;
        const columns = `(var(--tableItemWidth) + var(--tableOuterDistance)) * ${withEmptyLabels.length}`;
        const width = `calc(${columns} + ${gap} + 20px)`;
        this.$set(this.styles, "width", width);

        setTimeout(() => {
          const container = document.querySelector(".chart-box");

          if (container) {
            container.style.width = width;
          }
        }, 100);

        this.$set(this.chartData, "labels", withEmptyLabels);
        this.updateChart();
      },
      immediate: true,
    },
  },

  methods: {
    updateChart() {
      if (this.$refs.chart) {
        this.$refs.chart.renderChart(this.chartData, this.chartOptions);
      }
    },
  },
};
</script>
