var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-container-days",class:{
    'content-container-days--quarter': _vm.view.value === 'quarter',
    'content-container-days--month': _vm.view.value === 'month',
  }},[(_vm.view.value === 'month')?_vm._l((_vm.groupDaysByWeekdays),function(group,groupNumber){return _c('div',{key:`group_${_vm.userIndex}_${groupNumber}}`,staticClass:"content-container-wrapper"},[_vm._l((group.days),function(day){return _c('div',{key:`${day.dayInMonth}_${day.tasks.length}_${
          day.vacation ? day.vacation.status : 'v'
        }`,on:{"click":function($event){return _vm.showDayDetailsData(day, _vm.user, _vm.groupDaysByWeekdays)}}},[_c('content-day',{attrs:{"day":day}})],1)}),(parseInt(_vm.getWorkloadPercentage({ user: _vm.user, daysRange: group.daysRange })) > 0)?_c('div',{staticClass:"content-container-planner",style:({
          backgroundColor: _vm.getWorkloadScoreClassName(
            _vm.getWorkloadPercentage({ user: _vm.user, daysRange: group.daysRange })
          ),
        }),on:{"click":function($event){return _vm.showWorkloadPlannerData(group, _vm.user, group)}}}):_vm._e()],2)}):_vm._l((_vm.userSelectedWeeks.weeks),function(week,i){return _c('div',{key:`user-${_vm.user.id}-week-${i}`,staticClass:"table-week rel flex center",class:{
        }},[_c('content-week',{directives:[{name:"show",rawName:"v-show",value:(_vm.getUserWorkloadDataFromWeek(week)),expression:"getUserWorkloadDataFromWeek(week)"}],class:{
            'table-week-selected': _vm.selectedWeeks.find(
              (week) => 
               i === week.selectedIndex
              ) 
            },attrs:{"week":week},nativeOn:{"click":function($event){return ((event) => week.endDate && week.startDate ? _vm.showWeekDetailsData(week, _vm.user, event, i) : null).apply(null, arguments)}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }