import axios from 'axios'
import {tokenService} from "@/services/TokenService";

// export const BASE_URL = 'https://localhost:5001/';
export const BASE_URL = 'https://timetracker-table-backend.herokuapp.com/';

export const apiClient = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(config => {
    config.headers['X-Token'] = tokenService.accessToken;
    return config;
});
