var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-content-wapper quarter-faq"},[_c('div',{staticClass:"table-item table-content-item"},[_c('div',{staticClass:"rel table-content-avatar"}),_c('div',{staticClass:"rel table-name"}),_c('div',{staticClass:"quarter-faq-content"},[_c('div',{staticClass:"flex table-content-wapper-faq"},[_c('div',[_c('h6',[_vm._v("Plan")]),_c('div',{staticClass:"quarter-faq-container flex"},_vm._l((_vm.plans),function(plan){return _c('div',{key:plan.text,staticClass:"quarter-faq-items flex"},[_c('div',{staticClass:"quarter-faq-container__color",style:(plan.styles)}),_c('div',[_vm._v(" "+_vm._s(plan.text)+" ")]),(plan.projects)?_c('ul',{staticClass:"quarter-faq-items__list",staticStyle:{"position":"absolute"}},_vm._l((plan.projects),function(project,i){return _c('li',{key:project.name + i},[_vm._v(_vm._s(project.name))])}),0):_vm._e()])}),0)]),_c('div',[_c('h6',[_vm._v("Fact")]),_c('div',{staticClass:"quarter-faq-container flex"},_vm._l((_vm.facts),function(fact){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(fact.projects ? {
                              content: _vm.getTooltipList(fact.projects),
                              placement: 'top-end',
                              classes: ['week-info__table-footer-popup'],
                              container: '.table-content-wapper.quarter-faq',
                              boundariesElement: '.table-content',
                              offset: '5',
                              html: true,
                            } : null),expression:"fact.projects ? {\n                              content: getTooltipList(fact.projects),\n                              placement: 'top-end',\n                              classes: ['week-info__table-footer-popup'],\n                              container: '.table-content-wapper.quarter-faq',\n                              boundariesElement: '.table-content',\n                              offset: '5',\n                              html: true,\n                            } : null"}],key:fact.text,staticClass:"quarter-faq-items flex"},[_c('div',{staticClass:"quarter-faq-container__color",style:(fact.styles)}),(fact.text)?_c('div',[_vm._v(" "+_vm._s(fact.text)+" ")]):_vm._e(),(fact.images && fact.images.length > 0)?_c('div',{staticClass:"flex quarter-faq-container__images"},_vm._l((fact.images),function(image){return _c('div',{key:image},[_c('img',{attrs:{"src":_vm.getImgUrl(image)}})])}),0):_vm._e()])}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }