
import {Vue, Component, Prop} from "vue-property-decorator";
import {IProject} from "@/types/api";
import config from "@/config";

@Component
export default class QuarterFooter extends Vue {
    @Prop({ required: true })
    private nonBillableProjects: IProject[] | undefined;

    getImgUrl(img: string) {
        return require(`@/assets/${img}`)
    }

    get plans() {
      const {workloadStyles} = config;

      return [
        {
          text: '>120%',
          styles: {
            background: workloadStyles["--total-time-tracked-125"],
            filter: 'saturate(0.5) opacity(0.5)'
          }
        },
        {
          text: '100-120%',
          styles: {
            background: workloadStyles["--total-time-tracked-100"],
            filter: 'saturate(0.5) opacity(0.5)'
          }
        },
        {
          text: '75-100%',
          styles: {
            background: workloadStyles["--total-time-tracked-50"],
            filter: 'saturate(0.5) opacity(0.5)'
          }
        },
        {
          text: '0-75%',
          styles: {
            background: workloadStyles["--total-time-tracked-low"],
            filter: 'saturate(0.5) opacity(0.5)'
          }
        },
        {
          text: '0%',
          styles: {
            border: '1px solid #EDF2F9'
          }
        }
      ]
    }

  get facts() {
    const { workloadStyles } = config;
    return [
      {
        text: ">200%",
        styles: {
          background: workloadStyles["--total-time-tracked-fact-120"]
        }
      },
      {
        text: "120-200%",
        styles: {
          background: workloadStyles["--total-time-tracked-125"]
        }
      },
      {
        text: "100-120%",
        styles: {
          background: workloadStyles["--total-time-tracked-100"]
        }
      },
      {
        text: '75-100%',
        styles: {
          background: workloadStyles["--total-time-tracked-50"]
        }
      },
      {
        text: '0-75%',
        styles: {
          background: workloadStyles["--total-time-tracked-low"]
        }
      },
      {
        text: 'Non-billable',
        styles: {
          background: '#51CA99'
        },
        projects: this.nonBillableProjects ? [
          ...this.nonBillableProjects
        ] : []
      },
      {
        text: "",
        styles: {
          background: '#FFE923'
        },
        images: ['new-vacation.svg','sickDay.svg'],
      }
    ]
  }

  getTooltipList(projects: IProject[]) {
    if (!projects || !projects.length) return
    const items = projects.sort((a,b) => (a.timesheetColor > b.timesheetColor) ? 1 : ((b.timesheetColor > a.timesheetColor) ? -1 : 0)).reduce((acc, curr) => {
      if (curr.name && curr.timesheetColor) {
        acc += `<li><span class="project-color" style="background-color: ${curr.timesheetColor}"></span><span>${curr.name}</span></li>`
      }
      return acc
    }, '')
    return `<ul>${items}</ul>`
  }
}

