
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import ContentPosition from "../ContentPosition/ContentPosition.vue";
import ContentContainer from "../ContentContainer/ContentContainer.vue";
import { IProject, IProjectTime, ITeamStatus, ITimePlan, IWeekTime, IWorloadPlanner, ITimePlanDetails } from "@/types/api";
import { ITableMember, ITableMode, ITableMonthDay, ITableQuarterItem, ITableTask } from "@/types/time-table";
import { IGrouppedSkills } from "@/types/skill-filter"
import QuarterFooter from "../QuarterFooter/QuarterFooter.vue";
@Component({
  components: {
    ContentPosition,
    ContentContainer,
    QuarterFooter
  }
})
export default class TableSelect extends Vue {
  @Prop({ required: true })
  readonly skills!: IGrouppedSkills[] | [];

  @Prop({ required: true })
  public daysInMonth!: ITableMonthDay[];

  @Prop({ required: true })
  private appView!: ITableMode;

  @Prop({ required: true })
  private search!: string;

  @Prop({ required: true })
  private members!: Record<string, Record<string, ITableMember>>;

  @Prop({ required: true })
  public selectedProjects!: IProject[];

  @Prop({ required: true })
  public membersDataUpd!: string;

  @Prop({ required: true })
  private showWorkloadPlanner!: boolean;

  @Prop({ required: true })
  public quarter!: ITableQuarterItem[];

  @Prop({ required: true })
  public teamStatus!: Record<string, ITeamStatus>;

  @Prop({ required: true })
  public selectedWeeks!: unknown[];

  @Prop({ required: true })
  public weekTimes!: IWeekTime[]

  @Prop({ required: true })
  public timePlans!: ITimePlan[];

  @Prop({ required: true })
  public nonBillableProjects: IProject[] | undefined
  private width: any;
  public linePosition: Number = 0;
  membersFilteredSkills: Record<number, Record<string, string>> = {};
  private previousFilteredMembers: Record<string, Record<string, ITableMember>> = {};

  @Emit('showPlanned')
  showWorkloadPlannerData(wp) {
    return wp
  }

  @Emit("showDayDetails")
  showDayDetailsData(details) {
    return {
      data: details.day,
      user: details.user,
      daysGroup: details.daysGroup,
    };
  }

  @Emit("showWeekDetails")
  showWeekDetailsData(details) {
    return details;
  }

  get view() {
    return this.$store.state.timeTableView;
  }

  get activePositions() {
    return this.$store.state.activePositions
  }

  get appDataLoading() {
    return this.$store.state.appDataLoading
  }

  get dayTodayFlag() {
    return this.daysInMonth.find(item => item.isToday);
  }

  get selectedSkills() {
    return this.skills
      .filter(x => x.levels.filter(l => l.checked).length > 0)
      .map(x => ({
        grouppedName: x.grouppedName,
        levels: x.levels.filter(x => x.checked)
      }));
  }

  get filteredMembers() {
    let membersOff = {};
    let members: Record<string, Record<string, ITableMember>> = {};
    const search = (this.search.toLocaleLowerCase()).trim();

    Object.keys(this.members.Off || {}).forEach(key => {
      let hasUser = Object.values(this.members.Off[key].days).some(day => 'duration' in day);
      if (hasUser) {
        membersOff[key] = this.members.Off[key];
      }
    });

    if (Object.keys(this.members).length !== 0) {
      if (Object.keys(membersOff).length) {
        members = {...this.members, Off: membersOff}
      } else {
        members = {...this.members, Off: {}};
      }
    }

    Object.keys(members).forEach((pos => {
      if(Object.keys(members[pos]).length === 0){
        delete members[pos];
      }
    }));

    if ((!search && !this.selectedSkills.length && !this.selectedProjects.length)) {
      return members
    }

    if (this.appDataLoading) return this.previousFilteredMembers

    const searchTerms = search.split(' ');
    let isSkillTerm = false;
    let isPositionTerm = false;    
    let isNameTerm = false;    

    const tableMembers = Object.values(members).flatMap(nameMember => {
      const members = Object.values(nameMember);
      members.some(mbr => {
        if(!isPositionTerm)
          isPositionTerm = searchTerms.some(t => mbr.position.toLocaleLowerCase().includes(t));
        
        if(!isNameTerm)
          isNameTerm = searchTerms.some(t => mbr.name.toLocaleLowerCase().includes(t));
        
        const isSkillsTerms = Object.entries(mbr.skills).some(([skill, level]) => {
          const skillLc = skill.toLocaleLowerCase(),
              levelLc = level.toLocaleLowerCase();

          this.selectedSkills.some(t => {
            if (!isSkillTerm) isSkillTerm = skillLc == t.grouppedName.toLocaleLowerCase() && t.levels.map(l => l.level.toLocaleLowerCase()).includes(levelLc);
          })
          return isSkillTerm
        });
        return isPositionTerm && isSkillsTerms;
      });
      return members;
    });

    return tableMembers.reduce((all, member) => {
      const {name, position} = member;
      const memberName = name.toLocaleLowerCase();
      const memberPosition = position.toLocaleLowerCase();

      const selectedProjectsCheck = (member: ITableMember) => {
        let projectsFact;
        if (this.view.value === "month") {
          const daysTasks = Object.values(member.days).reduce(
              (all: ITableTask[], day) => {
                all.push(...day.tasks);
                return all;
              },
              []
          );
          projectsFact = daysTasks.some((s) =>
              this.selectedProjects.some((f) => f.id === s.project.id)
          );
        } else {         
          const weekTasks = member.weeks.flatMap((_) => [..._.projectsTimes]);
          projectsFact =  weekTasks.some((s) =>
            this.selectedProjects.some((f) => f.name.toLowerCase() === s.name.toLowerCase())
          );         
        }

          const getProjectsFromPlanningByMember = (memberId) => {
            const projectsPlan = new Set()
            const memberTimePlans = this.timePlans.map((item) => ({      
              startDate: item.startDate,
              endDate: item.endDate,
              plans: item.plans[memberId] 
            }) as ITimePlanDetails);
            if(!memberTimePlans) return []

            memberTimePlans.forEach((item) => {
              if(item.plans && item.plans.length) 
              return item.plans.forEach(project => {
                projectsPlan.add(project.projectName)
              })
            })

            return [...projectsPlan]
          }

        const memberProjects = getProjectsFromPlanningByMember(member.id)
        const hasPlannedProjects = memberProjects.some((s) =>
            this.selectedProjects.some((f) => {
              return f.name === s
            }) 
          );
          return hasPlannedProjects || projectsFact;
      };

      const selectedAnyProjects = this.selectedProjects.length;
      const selectedProjectCoincide = !selectedAnyProjects
          ? true
          : selectedProjectsCheck(member);


      const positionCoincide = searchTerms.some(t => memberPosition.toLocaleLowerCase().includes(t));
      const nameCoincide = memberName.toLocaleLowerCase().includes(search);   

      const matchedSkills = (isSkillTerm) ? Object.entries(member.skills).reduce((all, [skill, level]) => {
        if (level) {
          const skillLc = skill.toLocaleLowerCase(),
              levelLc = level.toLocaleLowerCase();

          const skillCoincide = this.selectedSkills.some(t => t.levels.map(l => l.level.toLocaleLowerCase()).includes(levelLc) && skillLc === t.grouppedName.toLocaleLowerCase());

          if (skillCoincide) all[skill] = level;
        }

        return all;
      }, {} as Record<string, string>) : {}

      const skillsCoincide = Object.keys(matchedSkills).length > 0 && Object.keys(matchedSkills).length == this.selectedSkills.length;

      if (selectedProjectCoincide
          && ((isNameTerm ? nameCoincide : isPositionTerm && positionCoincide) 
          && (!isSkillTerm || skillsCoincide))) 
          {
        if (!all[position]) {
          all[position] = {}
        }

        all[position][name] = member;

        if (skillsCoincide) {
          this.membersFilteredSkills[member.id] = matchedSkills;
        }
      }

      return all
    }, {} as Record<string, Record<string, ITableMember>>)
  }

  created() {
    // if (this.showWorkloadPlanner) {
    //   this.$store.dispatch("AccessWorkloadPlannerDataAsync");
    // }
    window.addEventListener("resize", this.resizeEvent);
  }

  destroyed() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  @Watch('$route')
  async changeRoute(): Promise<void> {
    this.updatePosition();
  }

  @Watch('search')
  @Watch('selectedProjects')
  async handleSearchAndProjectSelect(): Promise<void> {
    Object.keys(this.filteredMembers).forEach(position => {
      this.handleUsersPosition(position, "ADD_ACTIVE_POSITION")
    })
  }

  @Watch('filteredMembers')
  handleChangeFilteredMembers (value, oldValue) {
      this.previousFilteredMembers = value
  }

  mounted() {
    this.updatePosition();
  }

  handleUsersPosition(position, type = "TOGGLE_ACTIVE_POSITION") {
    this.$store.commit({
      type,
      payload: { position }
    })
  }

  isActive(position) {
    return this.activePositions.includes(position)
  }

  isHidden(position, users) {
    if (position !== '-- No Position --') return false;

    return !Object.values(users).length;
  }

  resizeEvent() {
    this.updatePosition();
  }

  @Watch('daysInMonth')
  updatePosition() {
    let current = this.daysInMonth.find(item => item.isToday);
    this.width = document.querySelector('.table-header');
    this.linePosition = current ? current.number * this.width.getBoundingClientRect().width : 0;
  }

  vw(v) {
    const w = Math.max(document.documentElement!.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
  }
}
