
import { IProject } from "@/types/api";
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import SelectSearch from "../SelectSearch/SelectSearch.vue";
import DetailsViewSelect from "../DetailsViewSelect/DetailsViewSelect.vue";
import { ITableWeek } from "@/types/time-table";
@Component({
    components: {
        SelectSearch,
        DetailsViewSelect
   }
})
export default class DetailsViewForm extends Vue {
    private isShowForm: boolean = false;    

    @Prop({ required: true })
    private options!: string[];
    
    @Prop({ required: true })
    private allProjects!: IProject[];

    get planOptions() {
        return this.options;
    }

    projectFields: {
        projectName: string,
        projectId: number,
        planTime: string,
        isAsap: boolean,
    } = {
        projectName: '',
        projectId: 0,
        planTime: 'N/A',
        isAsap: false,
    }

    validationErrors: {
        planTime: boolean,
        projectName: boolean
    } = {
        planTime: false,
        projectName: false
    }

    resetForm() {
        const f = this.projectFields;
        f.projectName = '';
        f.planTime = 'N/A',
        f.projectId = 0;
        f.isAsap = false
    }

    onAsap(isAsap: boolean) {
        this.projectFields.isAsap = isAsap;
    }
    
    onShowForm() {
        this.isShowForm = !this.isShowForm
        this.resetForm();
    }

    get projectNameOption() {
        return this.projectFields.projectName;
    }

    get allProjectOptions() {
        return this.allProjects;
    }

    @Watch('allProjects')
    handleResetForm() {
        this.resetForm();
    }

    @Emit('input')
    onInput(option) {
        this.projectFields.projectId = option.id || option;
        this.projectFields.projectName = option['name'] || option
        this.validationErrors.projectName = false;
    }

    @Emit('input')
    onInputPlanTime() {
        this.validationErrors.planTime = false;
    }

    async checkValidate() {
        const { planTime, projectName } = this.projectFields;
        if (planTime !== 'N/A' && projectName) {
           return Promise.resolve()
        }
        return Promise.reject({
            planTime: planTime === 'N/A' || !!planTime,
            projectName: !projectName
        })
    }

    createUserProjectValidated() {
        this.checkValidate().then(() => {
            this.createUserProject()
        }).catch(({ planTime, projectName }) => {
            this.validationErrors = {
                planTime,
                projectName,
            }
        })
    }

    @Emit('createUserProject')
    createUserProject() {
        const formData = {
            plan: this.projectFields.planTime,
            projectName: this.projectFields.projectName,
            isAsap: this.projectFields.isAsap,
            projectId: this.projectFields.projectId
        }
        this.validationErrors = {
            planTime: false,
            projectName: false
        };
        this.onShowForm();
        return formData;
    }
}
