
    import { IProject } from "@/types/api";
    import { Vue, Prop, Component, Emit } from "vue-property-decorator";
    
@Component

export default class DetailsViewSelect extends Vue {

    @Prop()
    private label: string | undefined;

    @Prop()
    private value!: string;

    @Prop({ required: true })
    private options!: IProject[];

    get selectOptions() {
        return this.options;
    }

    @Prop({ required: true })
    private isSearch!: boolean;

    get selectValue() {
        return this.value;
    }

    @Emit('input')
    change(value) {
            return value;
        }

    get currentUser() {
        return this.$store.state.currentUser
    }
}

