export class DurationHelper {
    static millisecondsToString(duration): string {
        let minutes: number = Math.floor((duration / (1000 * 60)) % 60),
            overHours: number = Math.floor((duration / (1000 * 60 * 60)) / 24),
            hours: number = Math.floor((duration / (1000 * 60 * 60)) % 24) + overHours * 24;

        let minutesText = (minutes < 10) ? "0" + minutes : minutes;
        return hours === 0 && minutesText === '00' ? '0:00' : hours + ":" + minutesText;
    }
    static timeToMilliseconds(time): number {
        let splitedNonBillable = time.split(":");
        return parseInt(splitedNonBillable[0]) * 60 * 60 * 1000 +
        parseInt(splitedNonBillable[1]) * 60 * 1000;
    }    
}
