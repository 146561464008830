<template>
  <LineChartGenerator
    ref="chart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :height="height"
  />
  <!-- :width="width" -->
</template>

<script>
import { Bar } from "vue-chartjs";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({
        // minWidth: "2551px",
        width: "40px",
        height: "350px",
        maxHeight: "350px",
        overflowY: "hidden",
      }),
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.data,
      },
      chartOptions: {
        scaleShowLabels: false,
        animantion: false,
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 0,
          },
        },
        layout: {
          padding: {
            bottom: 20,
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        // responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              categorySpacing: 10,
              barPercentage: 20,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
            grid: {
              display: false,
            },
            gridLines: {
              display: false,
            },
          },
          y: {
            stacked: true,
            afterFit: (ctx) => {
              console.log(ctx);
              ctx.width = 40;
            },
            gridLines: {
              display: false,
            },
          },
        },
        tension: 0,
      },
    };
  },
  watch: {
    data: {
      handler(data) {
        this.$set(
          this.chartData,
          "datasets",
          data.map((it) => ({
            ...it,
            borderColor: "transparent",
            backgroundColor: "transparent",
            fill: false,
          }))
        );
        this.updateChart();
      },
      immediate: true,
    },
    labels: {
      handler(labels) {
        const additionalLength = 29;
        const additionalLabels = additionalLength - labels.length;
        const withEmptyLabels =
          labels.length < additionalLabels
            ? labels.concat(new Array(additionalLabels).fill(null))
            : labels;
        this.$set(
          this.chartData,
          "labels",
          withEmptyLabels.map(() => "")
        );
        this.updateChart();
      },
      immediate: true,
    },
  },

  methods: {
    updateChart() {
      if (this.$refs.chart) {
        this.$refs.chart.renderChart(this.chartData, this.chartOptions);
      }
    },
  },
};
</script>
