
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component

    export default class BugReport extends Vue {
        get lastReloadFromNow() {
            return this.$store.state.lastReloadFromNow
        }

        get shortCacheUpdatedAt() {
            return this.$store.state.shortCacheUpdatedAt
        }

        get longCacheUpdatedAt() {
            return this.$store.state.longCacheUpdatedAt
        }

        get teamCacheUpdatedAt() {
            return this.$store.state.teamCacheUpdatedAt
        }

        get teamPositionsCacheUpdatedAt() {
            return this.$store.state.teamPositionsCacheUpdatedAt
        }
    }
