
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableMonthDay, ITableQuarterItem } from "@/types/time-table";

@Component
export default class TableHeader extends Vue {
  @Prop({ required: true })
  private daysInMonth!: ITableMonthDay[];

  @Prop({ required: true })
  private daysInMonthDataUpd!: string;

  @Prop({ required: true })
  private quarter!: ITableQuarterItem[];

  get view() {
    return this.$store.state.timeTableView;
  }

  get appDataLoading() {
    return this.$store.state.appDataLoading;
  }
}
