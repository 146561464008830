import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home/Home.vue')
const Login = () => import('../views/Login/Login.vue')
const Report = () => import('../views/Report/Report.vue')
const ProjectsInfo = () => import('../views/ProjectsInfo/ProjectsInfo.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
        component: Home,
        alias: '/TimeTable'
    },
    {
        path: '/:month/:year',
        name: 'TimeTable',
        component: Home,
    },
    {
        path: '/report',
        name: 'Report',
        component: Report
    },
    {
        path: '/projects-info',
        name: 'ProjectsInfo',
        component: ProjectsInfo
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    { path: "*", redirect: { name: 'Home' } }
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
