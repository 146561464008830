import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import {DurationHelper} from "@/services/DurationHelper";

dayjs.extend(duration)
dayjs.extend(utc)

export interface IReportUser {
    id: number,
    username: string,
    position: string,
    rate: number
}

export interface IReportRate {
    projectId: number,
    userId: number,
    value: number
}

export interface IReportData {
    projectId: number,
    taskId: number,
    taskName: string,
    billable: number,
    nonBillable: number,
    status: string,
    user: IReportUser,

    readonly billableText: string,
    readonly nonBillableText: string,
    readonly cost: number,
    readonly userName: string,
    readonly userPosition: string,
    readonly userRate: number,
    readonly taskUrl: string
}

export class ReportData implements IReportData {
    billable: number;
    nonBillable: number;
    taskId: number;
    projectId: number;
    taskName: string;
    status: string;
    user: IReportUser;

    constructor(billable: number, nonBillable: number, status: string, taskId: number, projectId: number,
                taskName: string, user: IReportUser) {
        this.billable = billable;
        this.nonBillable = nonBillable;
        this.status = status;
        this.taskId = taskId;
        this.projectId = projectId;
        this.taskName = taskName;
        this.user = user;
    }

    get cost(): number {
        return Number(Number(dayjs.duration(DurationHelper.timeToMilliseconds(this.billableText)).asHours() * this.user.rate).toFixed(2));
    }

    get userRate(): number {
        return Number(this.user.rate);
    }

    get userName(): string {
        return this.user.username;
    }

    get userPosition(): string {
        return this.user.position;
    }

    get billableText(): string {
        return DurationHelper.millisecondsToString(this.billable);
    }

    get nonBillableText(): string {
        return DurationHelper.millisecondsToString(this.nonBillable);
    }

    get taskUrl(): string {
        if (this.taskId !== 0) {
            return `https://app.clickup.com/t/${this.taskId}`;
        }
        return "#";
    }
}

export interface IReportGenerate {
    googleSheetUrl: string | null,
    errorCode: number,
    message: string | null,
}
