
import { Component, Vue, Prop } from "vue-property-decorator";
import {ITableDay, ITableTask} from "@/types/time-table";

const MAX_DOTS_COUNT = 15;

@Component
export default class TableContentDay extends Vue {
  @Prop({ required: true })
  private day!: ITableDay;

  @Prop({ required: false })
  private styles!: { [key: string]: any };

  get isVacation() {
    return this.day.vacation || this.day.sickDay
  }

  get isBenching() {
    return this.day.benchingPercent && (!this.day.duration || !this.day.benchingPercent)
  }

  get isHoliday() {
    return this.day.holiday
  }

  get isWorkday() {
    return this.day.workday
  }

  get isWeekend() {
    return this.day.weekend
  }

  get hasTasks() {
    return this.day.tasks && this.day.tasks.length > 0 || this.day.currentTask
  }

  get hasComment(){
    return this.day.comments && this.day.comments.length > 0
  }

  get vacationIcon() {
    const today = new Date();
    today.setHours(0,0,0,0);

    const dayDate = new Date(this.day.fullDate);
    const vacEndDate = new Date((this.day.vacation || this.day.sickDay)!.endDate!);
    const isUpcoming = dayDate >= today && dayDate <= vacEndDate;

    if (this.day.vacation) {
      return require('@/assets/' + (isUpcoming ? 'new-vacation-active.svg' : 'new-vacation.svg'))
    } else {
      return require('@/assets/' + (isUpcoming ? 'sickDay-active.svg' : 'sickDay.svg'))
    }
  }

  get isClosed() {
    return this.day.vacation
        ? this.day.vacation.status.name === 'Closed'
        : this.day.sickDay
            ? this.day.sickDay.status.name === 'Closed'
            : false
  }

  get hasLateCreatedTask() {
    if (!this.day) return 0
    return this.day.tasks.some((task: ITableTask) => {
      return task.createdDateHoursDiff / 12 > 1
    });
  }

  get overdueDatesCount() {
    if (!this.day) return 0
    return this.day.tasks.reduce((acc: number, task: ITableTask) => {
      let currentTaskOverdue = Math.floor(task.createdDateHoursDiff / 24)
      if (currentTaskOverdue > MAX_DOTS_COUNT) currentTaskOverdue = MAX_DOTS_COUNT
      return currentTaskOverdue > acc ? currentTaskOverdue : acc;
    }, 0);
  }
}
