
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { ITableMode } from "@/types/time-table";
import { getUniq } from "@/utils";
import { APP_VIEWS } from "../../config";

@Component
export default class SwitcherComponent extends Vue {
  private _firstUniqId: string = "";
  private _secondUniqId: string = "";

  private value: ITableMode = APP_VIEWS["month"];

  @Prop({ required: true })
  readonly legend!: string;

  @Prop({ required: true })
  readonly name!: string;

  @Prop({ required: true })
  readonly firstPosition!: ITableMode;

  @Prop({ required: true })
  readonly secondPosition!: ITableMode;

  @Prop({ required: true })
  readonly defaultPosition!: ITableMode;

  @Prop({ default: false })
  readonly disabled!: boolean;

  get firstPositionLabel(): string {
    return this.firstPosition.name;
  }

  get secondPositionLabel(): string {
    return this.secondPosition.name;
  }

  created() {
    this._firstUniqId = getUniq();
    this._secondUniqId = getUniq();

    this.value = this.defaultPosition;
  }

  @Watch("value")
  @Emit("change")
  handleChange(value: string): string {
    return value;
  }
}
