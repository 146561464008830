<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.59961 14.0813H4.68869L13.4411 5.32895C13.8507 4.91931 14.0809 4.36372 14.0809 3.78441C14.0809 3.20509 13.8507 2.6495 13.4411 2.23987C13.0315 1.83023 12.4759 1.6001 11.8965 1.6001C11.3172 1.6001 10.7616 1.83023 10.352 2.23987L1.59961 10.9923V14.0813Z" stroke="currentColor" stroke-width="1.5"/>
        <path d="M8.80762 3.78564L11.8967 6.87473" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'IconEdit'
}
</script>

<style scoped>

</style>