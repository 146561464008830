import { ITableMode } from "./types/time-table";

export const SERVICE_DATA_TEAM = "team";
export const SERVICE_DATA_POSITION = "position";
export const SERVICE_DATA_SKILLS = "skills";
export const SERVICE_DATA_VACATION = "vacation";
export const SERVICE_DATA_SICK_DAY = "sickDay";
export const SERVICE_DATA_HOLIDAY = "holiday";
export const SERVICE_DATA_WORKDAY = "workday";
export const SERVICE_DATA_TIMES = "times";
export const SERVICE_DATA_WEEK_TIMES = "week_times";
export const SERVICE_DATA_TIMES_CURRENT = "times_current";
export const SERVICE_DATA_COMMENT = "comments";

export const APP_VIEWS: Record<'quarter' | 'month', ITableMode> = {
    month: { name: 'Month', value: 'month' },
    quarter: { name: 'Quarter', value: 'quarter' },
}

export const NO_PROJECT:string = "(no project)";

const INITIAL_POSITION = "Frontend";

const WORKLOAD_STYLES = {
    '--total-time-tracked-150': '#000',
    '--total-time-tracked-125': '#222222',
    '--total-time-tracked-100': '#869FCA',
    '--total-time-tracked-50': '#A7BFE9',
    '--total-time-tracked-low': '#CCDEFD',
    '--total-time-tracked-bench': '#90DFC0',
    '--total-time-tracked-fact-120': 'rgb(252, 96, 92)',

    '--total-time-planner-0': '#EDF2F9',
    '--total-time-planner-30': '#C4D6EC',
    '--total-time-planner-80': '#8396AE',
    '--total-time-planner-100': '#1E252D',

    '--benching': '#90DFC0',

    '--color-primary': 'white',
    '--color-secondary': 'black',

    '--workload-planner-100': 'rgb(0 0 0)',
    '--workload-planner-75': 'rgb(0 0 0 / 25%)',
    '--workload-planner-50': 'rgb(0 0 0 / 50%)',
    '--workload-planner-25': 'rgb(0 0 0 / 20%)',
    '--workload-planner-support': 'rgb(0 0 0 / 50%)',
    '--workload-planner-free': 'white',
    '--workload-planner-no-info': 'lightgrey',
    '--workload-planner-running': '#637FAE',
}

const TEAM_STATUS_AVAILABILITY_COLUMN = 'Доступность'
const TEAM_STATUS_NAME_COLUMN = 'Name'
const TEAM_STATUS_CONNECTION_COLUMN = 'Интернет'

export enum teamStatusConnectionConstants {
    TEAM_STATUS_LOW_CONNECTION = 'слабый',
    TEAM_STATUS_HAS_CONNECTION = 'есть',
    TEAM_STATUS_NO_CONNECTION = 'нет',
}

const config = {
    workloadStyles: WORKLOAD_STYLES,
    initialPosition: INITIAL_POSITION,
    teamStatus: {
        columnNames: {
            name: TEAM_STATUS_NAME_COLUMN,
            availability: TEAM_STATUS_AVAILABILITY_COLUMN,
            connection: TEAM_STATUS_CONNECTION_COLUMN
        },
    }
}

export default config
