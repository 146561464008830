
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { IDayData } from '@/types/api';
    import { ITableDay, ITableTask, ITableDayGroup } from '@/types/time-table';
    import {
      convertMinutesToTimeString,
      getDayColumnStyles,
      getTotalMinutes,
      getNumberOfDays,
      getTotalDaysRange,
    } from '@/utils';
    import ContentDay from '../ContentDay/ContentDay.vue';
    import config from "@/config";

    @Component({
        components: {
            ContentDay,
        },
    })

    export default class AverageTime extends Vue {
        @Prop({ required: true })
        private dayData!: IDayData & { daysGroup: ITableDayGroup[] };

        @Prop({ required: true })
        private userDays!: ITableDay[];

        nonBillableTasksByColors(tasks: ITableTask[]) {
          if (!tasks || (tasks && !tasks.length)) return null
          return tasks.reduce((acc, curr) => {
            const color = curr.project.timesheetColor || config.workloadStyles["--benching"]
            if (!acc[color]) acc[color] = []
            acc[color].push(curr)
            return acc
          }, {}) as Record<string, ITableTask[]>
        }

        get averageMemberTime() {
            if (this.userDays) {
                const days = Object.values(this.userDays);
                const today = new Date().getDate();
                const isCurrentMonth = new Date().getMonth() === this.timeTableMonth;
                const numberOfDays = isCurrentMonth
                    ? today
                    : getNumberOfDays(this.timeTableYear, this.timeTableMonth)

                const numberOfVacations = {}

                const fillNumberOfVacations = data => {
                    /** vacations & holidays */
                    const startDate = new Date(data.startDate);
                    const endDate = new Date(data.endDate);

                    getTotalDaysRange(startDate, endDate, this.timeTableMonth)
                        .forEach(d => numberOfVacations[d] = true)
                }

                const daysWithHolidays = this.dayData.daysGroup.reduce((acc, item) => {
                    item.days.forEach(d => (acc[d.dayInMonth] = d))
                    return acc
                }, {})

                const isToday = dayDate => isCurrentMonth && dayDate === today

                /** number of working days until today's date */
                const numberOfWorkingDays = Array.from({ length: numberOfDays }, (_, x) => ++x)
                    .filter(d => {
                        const vacationMaybe = daysWithHolidays[d].vacation;
                        const holidayMaybe = daysWithHolidays[d].holiday;
                        const sickDayMaybe = daysWithHolidays[d].sickDay;

                        if (vacationMaybe) {
                            fillNumberOfVacations(vacationMaybe)
                        }
                        if (holidayMaybe) {
                            fillNumberOfVacations(holidayMaybe)
                        }
                        if (sickDayMaybe) {
                            fillNumberOfVacations(sickDayMaybe)
                        }
                        /** do not include today's date as time may currently being tracked */
                        if (isToday(d)) return false;

                        return !daysWithHolidays[d].weekend || daysWithHolidays[d].workday
                    }
                ).filter(d => !numberOfVacations[d]).length

                if (!numberOfWorkingDays) return null;
                /**
                 * no today, nor weekend, nor holiday\sickleave
                 * */
                const checkIsDayValid = (d, i) => d.duration && !isToday(i + 1)

                const daysWithTime = days.filter(checkIsDayValid)
                    .reduce((timeAccumulator, item) => {
                        /** accumulate total time tracked until today's date
                         * p.s. it might be possible to use day.percent instead
                         * but max % is 100, and a member may have more that 8 h tracked per day
                         */
                        const { duration } = item;

                        if (!duration || duration === "0") return '0:00';

                        const hours = parseInt(duration.split(':')[0]);
                        const minutes = parseInt(duration.split(':')[1]);

                        const accHours = parseInt(timeAccumulator.split(':')[0]);
                        const accMinutes = parseInt(timeAccumulator.split(':')[1]);

                        let totalHours = hours + accHours
                        let totalMinutes: string | number = minutes + accMinutes

                        if (totalMinutes > 60) {
                            totalHours = totalHours + 1
                            totalMinutes = totalMinutes % 60
                        }

                        if (totalMinutes < 10) {
                            totalMinutes = '0' + totalMinutes
                        }

                        return `${totalHours}:${totalMinutes}`
                    }, '0:00')

                if (daysWithTime === '0:00') return null

                const workingDayMinutes = 480;

                const hours = parseInt(daysWithTime.split(':')[0]);
                const minutes = parseInt(daysWithTime.split(':')[1]);

                const hoursToMinutes = hours * 60;
                /** get arithmetic mean from total time accumulated  */
                const averageTotalMinutes = (hoursToMinutes + minutes) / numberOfWorkingDays;
                const averageHours = (averageTotalMinutes - (averageTotalMinutes % 60)) / 60;
                const averageMinutes = Math.floor(averageTotalMinutes - averageHours * 60);

                const averageBenchingPercent =
                    days.reduce((benchingAccumulator, item) => {
                        const { benchingPercent } = item

                        benchingAccumulator = benchingPercent
                            ? benchingAccumulator + benchingPercent
                            : benchingAccumulator

                        return benchingAccumulator
                    }, 0) / numberOfWorkingDays

                const averageTimeTracked = `${averageHours}:${averageMinutes < 10 ? '0' + averageMinutes : averageMinutes}`;
                const averagePercent = averageTotalMinutes / workingDayMinutes * 100;

                const tasks = Object.values(this.userDays)
                    .filter((d, i) => d.tasks.length && !isToday(i + 1))
                    .reduce((acc, d) => { acc = [...acc, ...d.tasks]; return acc }, [] as ITableTask[]);


                const noBench = tasks.filter(task => !task.project.isNonBillable);
                const bench = tasks.filter(task => task.project.isNonBillable);

                let benchMinutes = 0;
                const tasksMinutes = getTotalMinutes(tasks);
                const taskMinutesWithoutBench = getTotalMinutes(noBench);
                const total = tasksMinutes > workingDayMinutes ? tasksMinutes : workingDayMinutes;
                const nonBillableByColors = this.nonBillableTasksByColors(bench)

                const nonBillableList:
                    Record<string, { percentsFromTotalTime: number | null, percentsFromWorkingDay: number | null }> = {} // @TODO Rename

                for (const color in nonBillableByColors) {
                  const nonBillableMinutes = getTotalMinutes(nonBillableByColors[color]);
                  if (!nonBillableList[color]) nonBillableList[color] = {
                    percentsFromTotalTime: 0,
                    percentsFromWorkingDay: 0
                  }

                  nonBillableList[color].percentsFromTotalTime = (nonBillableList[color].percentsFromTotalTime || 0) + (nonBillableMinutes / total) * 100
                  nonBillableList[color].percentsFromWorkingDay = (nonBillableList[color].percentsFromTotalTime || 0) / 100 * averagePercent

                  benchMinutes += (nonBillableMinutes)
                }

                const style = getDayColumnStyles(averageTimeTracked, averagePercent, nonBillableList)

                return {
                    workHoursTotal: convertMinutesToTimeString(taskMinutesWithoutBench),
                    workHoursAverage: convertMinutesToTimeString(taskMinutesWithoutBench / numberOfWorkingDays),
                    benchHoursTotal: convertMinutesToTimeString(benchMinutes),
                    benchHoursAverage: convertMinutesToTimeString(benchMinutes / numberOfWorkingDays),
                    averageDayData: {
                        benchingPercent: averageBenchingPercent,
                        duration: averageTimeTracked,
                        endTime: "",
                        startTime: "",
                        tasks: [{}],
                        percent: 0,
                        style,
                    }
                }
            }
            return null
        }

        get lastReloadFromNow() {
            return this.$store.state.lastReloadFromNow
        }

        get longCacheUpdatedAt() {
            return this.$store.state.longCacheUpdatedAt
        }

        get shortCacheUpdatedAt() {
            return this.$store.state.shortCacheUpdatedAt
        }

        get teamCacheUpdatedAt() {
            return this.$store.state.teamCacheUpdatedAt
        }

        get teamPositionsCacheUpdatedAt() {
            return this.$store.state.teamPositionsCacheUpdatedAt
        }

        get timeTableMonth() {
            return this.$store.state.timeTableMonth
        }

        get timeTableYear() {
            return this.$store.state.timeTableYear
        }
    }
