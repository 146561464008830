
import { MonthYearEntry } from "@/views/ProjectsInfo/types";
import dayjs from "dayjs";
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({ components: {} })
export default class ProjectsInfoTableHeader extends Vue {
  @Prop({ required: true })
  public months!: {
    months: {
      isStartOfTheYear: boolean;
      startDate: Date;
      endDate: Date;
    }[];
    year: number;
  }[];

  get monthColumns() {
    return this.months.map((it) => ({
      ...it,
      months: it.months.map((date) => ({
        ...date,
        month: dayjs(date.startDate).get("month") + 1,
      })),
    }));
  }
}
