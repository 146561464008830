
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { ITimePlanDetails, IWorloadPlanner } from "@/types/api";
    import { ProgressBar } from '@/components/Shared';
    import {getWorkloadPlannerTotalScore, sortWorkloadPlanner} from "@/utils/workloadHelper";
    import config from '@/config';
    import { timeToPercent } from "@/utils";

    @Component({
        components: { ProgressBar }
    })


    export default class PlannerInfo extends Vue {
        public workloadPlannerExceedValue: number = 125;

        // @Prop({ required: true })
        // private plannerData!: IWorloadPlanner[];

        @Prop({ required: true })
        private timePlansDetails!: ITimePlanDetails[];

        // get getPlannerTotalScore() {
        //     return getWorkloadPlannerTotalScore(this.plannerData)
        // }

        get getTimePlansDetails() {
            const [timePlansDetails] = this.timePlansDetails.map((timePlan) => ({
                ...timePlan,
                plans: timePlan.plans && timePlan.plans.length > 0 && timePlan.plans.filter((plan) => plan.totalMilliseconds > 0).map((plan) => ({
                    ...plan,
                    percent: timeToPercent(plan.totalMilliseconds, 40)
                })) || []
            }));
            const totalPercent = this.timePlansDetails &&
                this.timePlansDetails.length > 0 &&
                this.timePlansDetails.reduce((acc, curr) => acc += curr.plans.reduce((plans, plan) => plans += plan.totalMilliseconds, 0), 0)
                || 0;

            return {
                plans: [...timePlansDetails.plans].sort((a,b) => b.totalMilliseconds - a.totalMilliseconds) || [],
                totalPercent: timeToPercent(totalPercent, 40)
            };
        }

        // get noFreePlannerData() {
        //     return this.plannerData.filter(project => project.workload !== 'free')
        // }

        // get sortedPlannerData() {
        //     return this.noFreePlannerData.sort(sortWorkloadPlanner)
        // }


        getWorkPlannerClassName(str) {
            return config.workloadStyles[
                `--workload-planner-${str.replace(/ /g, "-").replace(/%/g, "")}`
            ];
        }

        getPercentage(workload) {
            return (workload === "support" || workload === "free" || workload === "no info") ? '0%' : workload
        }
    }
