
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { IGrouppedSkills } from "@/types/skill-filter"
import IconClose from '@/components/Icons/IconClose.vue'
import IconCheck from '@/components/Icons/IconCheck.vue'


@Component({
  components: {
    IconClose,
    IconCheck,
  },
})


export default class SkillsFilter extends Vue {
  @Prop({ required: true })
  readonly skillSelectShow!: boolean;

  @Prop({ required: true })
  readonly skillItems!: IGrouppedSkills[];
 
  @Emit("openCloseSkillFilter")
  handleOpenCloseSkillFilter(value) {
    return value;
  }

  isInGroupMenu(grouppedName :string) {
    return this.skillItems.some((item) => item.grouppedName === grouppedName && item.levels.some(level => level.checked));
  }

  allGroupChecked(grouppedName :string) {
    return this.skillItems.some((item) => item.grouppedName === grouppedName && item.levels.every(level => level.checked));
  }

  groupChecking(grouppedName :string) {
    let isAllChecked = this.skillItems.some((item) => item.grouppedName === grouppedName && item.levels.every(level => level.checked));
    let skillItem = this.skillItems.find(item => item.grouppedName === grouppedName);
    if(skillItem !== undefined) {
      skillItem.levels.forEach(function(level) {
        level.checked = !isAllChecked;
      });
    }
  }
}

