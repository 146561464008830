
import { Component, Prop, Vue } from "vue-property-decorator";
import { IGrouppedSkills } from "@/types/skill-filter"
import { FilterSkillsClose } from "@/icons";



@Component({
  components: {
    FilterSkillsClose
  }
})
export default class TableHeaderFilterSkills extends Vue {
  @Prop({ required: true })
  readonly skills!: IGrouppedSkills[] | [];

  get view() {
    return this.$store.state.timeTableView;
  }

  get appDataLoading() {
    return this.$store.state.appDataLoading;
  }

  get selectedSkills() {
    return this.skills
      .filter(x => x.levels.filter(l => l.checked).length > 0)
      .map(x => ({
        grouppedName: x.grouppedName,
        levels: x.levels.filter(x => x.checked)
      }));
  }

  allGroupChecked(grouppedName :string) {
    return this.skills.some((item) => item.grouppedName === grouppedName && item.levels.every(level => level.checked));
  }

  uncheckedGroup(grouppedName :string) {
    this.skills.forEach((item) => item.grouppedName === grouppedName && item.levels.forEach(level => level.checked = !level.checked));
  }
}
