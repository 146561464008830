
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  clamp, getDayColumnStyles, getTotalMinutes, msToTime, tasksPercentTimes,
  timeToPercent,
} from "@/utils";
import {ITableNonBillable, ITableWeek} from "@/types/time-table";
import {IProjectTime} from "@/types/api";
import config from "@/config";

@Component
export default class ContentWeek extends Vue {
  private MINUTE: number = 1000 * 60;
  private HOUR: number = this.MINUTE * 60;
  private TOTAL_HOURS_MS: number = 40 * this.HOUR;

  @Prop({ required: true })
  private week!: ITableWeek;

  nonBillableTimesByColors(times: IProjectTime[]) {
    if (!times || (times && !times.length)) return null
    return times.reduce((acc, curr) => {
      const color = curr.timesheetColor || config.workloadStyles["--benching"]
      if (!acc[color]) acc[color] = []
      acc[color].push(curr)
      return acc
    }, {}) as Record<string, IProjectTime[]>
  }

  get time() {
    const nonBillable = this.week.nonBillable ? this.week.nonBillable.reduce((acc, item) => {
      if (item.projectTimes && item.projectTimes.length) {
        acc = [...acc, ...item.projectTimes]
      }
      return acc
    }, [] as IProjectTime[]) : [];
    const nonBillableByColors = this.nonBillableTimesByColors(nonBillable)
    const totalFactMs = this.week.projectsTimes.reduce((durationMs, project) => durationMs += +project.durationMs || 0, 0);
    const totalPlanMs = this.week.projectsTimes.reduce((plansTime, project) => plansTime += +project.planMs || 0, 0);
    const planTime = timeToPercent(totalPlanMs);

    const nonBillableList:
        Record<string, { percentsFromTotalTime: number, percentsFromWorkingDay: number }> = {} // @TODO Rename

    const totalWeekMs = this.holidayCount ? this.TOTAL_HOURS_MS / this.holidayCount : this.TOTAL_HOURS_MS

    for (const color in nonBillableByColors) {
      const nonBillableMs = nonBillableByColors[color].reduce((total, curr) => total + curr.planMs, 0);
      if (!nonBillableList[color]) nonBillableList[color] = {
        percentsFromTotalTime: 0,
        percentsFromWorkingDay: 0
      }

      nonBillableList[color].percentsFromTotalTime = nonBillableList[color].percentsFromTotalTime + (nonBillableMs / totalPlanMs) * 100
      nonBillableList[color].percentsFromWorkingDay = nonBillableList[color].percentsFromWorkingDay + (nonBillableMs / totalWeekMs) * 100
    }

    const planStyle = getDayColumnStyles(msToTime(totalPlanMs), +(planTime.slice(0, planTime.length - 1)), nonBillableList, 'quarter');

    const totalFactPercent = timeToPercent(this.week.projectsTimes.reduce((factMsTime, project) => factMsTime += +project.durationMs || 0, 0));
    return {
      planTime,
      totalPlanMs,
      totalFactMs,
      totalFactPercent,
      planStyle,
    };
  }

  get factPercent() {
    return this.week.workingPercent ? this.week.workingPercent / 100 : 0;
  }

  get isPlanFull() {
    return parseInt(this.time.planTime) >= 100;
  }

  get sickDaysCount(): number {
    return this.week.sickDay || 0;
  }

  get vacationCount(): number {
    return this.week.vacation || 0;
  }

  get holidayCount():number {
    return this.week.holiday || 0;
  }

  get benchingPercent() {
    return this.week.benchingPercent ? this.week.benchingPercent / 100 : 0;
  }

  get nonBillableWithSpan() {
    return this.week.nonBillable ? this.week.nonBillable.reduce((acc, curr) => {
      if (!acc.length) {
        acc.push({
          nonBillable: curr,
          start: 0
        })
        return acc
      }

      const lastAccPercent = acc[acc.length - 1].nonBillable.percent + acc[acc.length - 1].start
      acc.push({
        nonBillable: curr,
        start: lastAccPercent
      })

      return acc
    }, [] as { nonBillable: ITableNonBillable, start: number }[]) : []
  }

  get vacationsAndSickDaysPercent() {
    const WEEK_WORK_DAYS_COUNT = 5;
    return clamp(
      (this.sickDaysCount + this.vacationCount) / WEEK_WORK_DAYS_COUNT,
      0,
      1
    );
  }

  get totalPercent() {
    return (this.factPercent + this.benchingPercent) * 100
  }

  getFactBGC(percent) {
    const { workloadStyles } = config;

    let rgba = workloadStyles["--total-time-tracked-100"];

    if (percent <= 75) {
      rgba = workloadStyles["--total-time-tracked-low"];
    } else if (percent <= 100 && percent > 75) {
      rgba = workloadStyles["--total-time-tracked-50"];
    } else if (percent <= 120 && percent > 100) {
      rgba = workloadStyles["--total-time-tracked-100"];
    } else if (percent <= 200 && percent > 120) {
      rgba = workloadStyles["--total-time-tracked-125"];
    } else if (percent > 200) {
      rgba = workloadStyles["--total-time-tracked-fact-120"];
    }

    return rgba
  }
}
