export function msToTime(durationMs: number) {
    const minute = 1000 * 60
    const hour = minute * 60

    let minutes = Math.floor((Math.abs(durationMs) / (minute)) % 60),
        overHours = Math.floor((Math.abs(durationMs) / (hour)) / 24),
        hours = Math.floor((Math.abs(durationMs) / (hour)) % 24) + overHours * 24,
        sign = durationMs < 0 ? '-' : ''

    let minutesText = (minutes < 10) ? "0" + minutes : minutes;
    return hours === 0 && minutesText === '00' ? "0:00" : sign + hours + ":" + minutesText;
}

export function tasksMsToTime(tasks) {
    if (!tasks || !tasks.length) return null;

    let durations = tasks.map(item => item.duration.totalMilliseconds).reduce((a, b) => (+a + +b), 0);

    return msToTime(durations);
}

export function percentTimes(durationsMs: number, totalMinutes = 480) {
    let minutes = Math.floor((durationsMs / (1000 * 60)));
    let percent = Number((minutes !== 0 ? (minutes / totalMinutes) * 100 : 0).toFixed(2));
    return percent ? percent > 100 ? 100 : percent : 0;
}

export function tasksPercentTimes(tasks, totalMinutes = 480) {
    if (!tasks || !tasks.length) return 0;
    let duration = tasks.map(item => item.duration.totalMilliseconds).reduce((a, b) => +a + +b, 0);
    return percentTimes(duration, totalMinutes);
}

export function getTotalMinutes(entity) {
    return entity.reduce((acc, time) => {
        acc = acc + time.duration.totalMinutes
        return acc
    }, 0);
}

export function getMinTime(tasks, start) {
    return tasks.reduce((min, t) => {
        if (min > t.startTime) {
            min = t.startTime
        }
        return min
    }, start)
}

export function getMaxTime(tasks, start) {
    return tasks.reduce((max, t) => {
        if (t.endTime > max) {
            max = t.endTime
        }
        return max
    }, start)
}

export function convertMinutesToTimeString(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesFinal = Math.floor(minutes % 60);

    return `${hours < 10 ? '0' + hours : hours}:${minutesFinal < 10 ? '0' + minutesFinal : minutesFinal}`
}

export function timeToMs(plan: string) {
    const [hours, minutes, seconds] = plan.split(':');
    const hoursMilliseconds = (+hours || 0) * 60 * 60 * 1000;
    const minutesMilliseconds = (+minutes || 0) * 60 * 1000;
    const secondsMilliseconds = (+seconds || 0) * 1000;

    return {
        hoursMilliseconds,
        minutesMilliseconds,
        secondsMilliseconds,
        totalMilliseconds: hoursMilliseconds + minutesMilliseconds + secondsMilliseconds
    };
}

export const timeToPercent = (time: number, hours: number = 40) => {
    const millisecondsInHour = 60 * 60 * 1000;
    const millisecondsHours = hours * millisecondsInHour;

    return `${Math.floor((time / millisecondsHours) * 100)}%`;
}
