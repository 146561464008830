
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import {
  msToTime,
  hasAnyNoInfoProjects,
  timeToMs
} from "@/utils";
import { ITableWeek } from "@/types/time-table";
import { IProject, IProjectTime, IWorloadPlanner } from "@/types/api";
import DetailsViewSelect from "../DetailsViewSelect/DetailsViewSelect.vue";
import DetailsViewForm from "../DetailsViewForm/DetailsViewForm.vue";
import SelectSearch from "../SelectSearch/SelectSearch.vue";
import dayjs from "dayjs";
import config, { NO_PROJECT } from "@/config";

@Component({
  computed: {
    config() {
      return config
    }
  },
  components: {
    DetailsViewSelect,
    DetailsViewForm,
    SelectSearch,
  }
})
export default class WeekInfo extends Vue {
  private planOptions = ['N/A', '2:00', '4:00', '6:00', '8:00', '10:00', '12:00', '14:00', '16:00', '20:00', '22:00', '24:00', '26:00', '28:00', '30:00', '32:00', '34:00', '36:00', '38:00', '40:00'];

  @Prop({ required: true })
  private allProjects!: IProject[];

  @Prop({ required: true })
  private selectedWeeks!: ITableWeek[]

  @Prop({ required: true })
  readonly weekData!: {
    weekDetails: ITableWeek;
    workloadData: IWorloadPlanner[];
    workloadDataWeeks: IWorloadPlanner[][];
  };

  get no_project() {
      return NO_PROJECT;
  }

  get currentUser() {
    return this.$store.state.currentUser
  }

  get sortedSelectedWeeks() {
    return [...this.selectedWeeks]
      .sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
  }

  get projectsTimes() {
    return this.selectedWeeks.map(({ projectsTimes }) => projectsTimes || []).flat()
  }

  get workloadData(): IWorloadPlanner[] {
    return this.weekData.workloadData;
  }

  get startDate() {
    const startDate = new Date(this.sortedSelectedWeeks[0].startDate);
    return `${startDate.getDate()}.${startDate.getMonth() + 1}`;
  }

  get endDate() {
    const endDate = new Date(this.sortedSelectedWeeks[this.sortedSelectedWeeks.length - 1].endDate)
    return `${endDate.getDate()}.${endDate.getMonth() + 1}`;
  }

  get vacationsTitle() {
    const vacations = this.weekData.weekDetails.vacations
    if (!vacations || !vacations.length) return undefined

    let vacationsStr = ''

    if (vacations.length === 1) {
      const vacationItem = vacations[0]
      vacationsStr = `${dayjs(vacationItem.startDate).format('DD.MM.YYYY')} – ${dayjs(vacationItem.endDate).format('DD.MM.YYYY')}`
    } else {
      vacationsStr = vacations.reduce((acc, curr) => {
        return acc + '\n' + `${dayjs(curr.startDate).format('DD.MM.YYYY')} – ${dayjs(curr.endDate).format('DD.MM.YYYY')}`
      }, '')
    }
    return `Vacations: ${vacationsStr}`
  }

  get sickDaysTitle() {
    const sickDays = this.weekData.weekDetails.sickDays
    if (!sickDays || !sickDays.length) return undefined

    let sickDaysStr = ''

    if (sickDays.length === 1) {
      const sickDayItem = sickDays[0]
      sickDaysStr = `${dayjs(sickDayItem.startDate).format('DD.MM.YYYY')} – ${dayjs(sickDayItem.endDate).format('DD.MM.YYYY')}`
    } else {
      sickDaysStr = sickDays.reduce((acc, curr) => {
        return acc + '\n' + `${dayjs(curr.startDate).format('DD.MM.YYYY')} – ${dayjs(curr.endDate).format('DD.MM.YYYY')}`
      }, '')
    }
    return `Sick Days: ${sickDaysStr}`
  }

  get vacationsCountWeeks() {
    return this.selectedWeeks.reduce((sum, current) => sum += current.vacation || 0, 0)
  }

  get sickDaysCountWeeks() {
    return this.selectedWeeks.reduce((sum, current) => sum += current.sickDay || 0, 0)
  }

  get isAllHaveWorkloadInfo(): boolean {
    return !hasAnyNoInfoProjects(this.workloadData);
  }

  get projects() {
    const projects = this.projectsTimes
      .reduceRight<Array<IProjectTime & {planTime: string | null, durationTime: string}>>((weeks, currentWeek) => {

        const found = weeks.find(week => week.name === currentWeek.name);
        if (!found && currentWeek) {
          const durationMs = this.projectsTimes
            .filter(({ name }) => name === currentWeek.name)
            .reduce((acc, { durationMs }) => acc += +durationMs || 0, 0);

          const currentProjects = this.projectsTimes
            .filter(({ name }) => name === currentWeek.name);

          const planMsProject =
            currentProjects.every(({ planMs }) => planMs === currentProjects[0].planMs) &&
            currentProjects.length === this.selectedWeeks.length
              ? currentProjects[0].planMs
              : 0;
            // .reduce((acc, { planMs }) => acc += planMs, 0);
          const planMs = currentProjects.reduce((acc, curr) => acc += curr.planMs, 0);

          return [...weeks,
            {
              ...currentWeek,
              planTime: planMsProject ? msToTime(planMsProject) : null,
              durationTime: msToTime(durationMs),
              planMs,
              durationMs,
              lastModified: dayjs(currentWeek.lastModified).format('H:mm DD.MM.YYYY')
            }
          ]
        }
        return weeks;
      }, []);

      // Billable
    const billableFact = msToTime(projects.filter(({ isNonBillable }) => !isNonBillable).reduce((acc, project) => acc += +project.durationMs || 0, 0));
    const billablePlan = msToTime(projects.filter(({ isNonBillable }) => !isNonBillable).reduce((acc, project) => acc += +project.planMs || 0, 0));

    // NonBillable
    const nonBillableFact = msToTime((this.selectedWeeks.reduce((acc, { benchingDuration }) => acc+= (typeof benchingDuration === 'string' ? timeToMs(benchingDuration).totalMilliseconds : 0), 0 )))
    const nonBillablePlan = msToTime(
      this.projectsTimes
        .filter(({ isNonBillable }) => isNonBillable)
        .reduce((acc, { planMs }) => acc += +planMs, 0)
    );

    // Total Billable + NonBillable
    const totalFact = msToTime(projects.reduce((acc, project) => acc += +project.durationMs || 0, 0));
    const totalPlan = msToTime(projects.reduce((acc, project) => acc += +project.planMs || 0, 0));
    
    return {
      projects: [...projects].sort((a,b) => b.durationMs - a.durationMs),
      nonBillableFact,
      nonBillablePlan,
      totalPlan,
      totalFact,
      billableFact,
      billablePlan
    };
  }

  get allProjectsOptions() {
    return this.allProjects.filter((option) => !this.projects.projects.find((project) => option.name === project.name));
  }

  changePlan(plan: string) {
    if (plan === 'N/A') return null;
    return timeToMs(plan).totalMilliseconds;
  }

  @Emit('changeUserProject')
  changeUserProject(project, previous) {
    return {
      project,
      previous,
    };
  }

  @Emit('createUserProject')
  createUserProject(formData) {        
    return formData;
  }

} 
