
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import {
        SERVICE_DATA_TEAM,
        SERVICE_DATA_POSITION,
        SERVICE_DATA_SKILLS,
        SERVICE_DATA_VACATION,
        SERVICE_DATA_SICK_DAY,
        SERVICE_DATA_HOLIDAY,
        SERVICE_DATA_WORKDAY,
        SERVICE_DATA_TIMES,
    } from "../../config"

    @Component

    export default class TableSelect extends Vue {
        private isFixed: boolean = false;
        private percentage: number = 0;;
        private counterIds: number[] = [];
        private counterKeys: string[] = [];

        private config = {
            [SERVICE_DATA_TEAM]: {
                loaded: false,
                percentage: 10,
                maxPercentage: 10,
            },
            [SERVICE_DATA_POSITION]: {
                loaded: false,
                percentage: 5,
                maxPercentage: 15,
            },
            [SERVICE_DATA_SKILLS]: {
                loaded: false,
                percentage: 5,
                maxPercentage: 15,
            },
            [SERVICE_DATA_VACATION]: {
                loaded: false,
                percentage: 10,
                maxPercentage: 25,
            },
            [SERVICE_DATA_SICK_DAY]: {
                loaded: false,
                percentage: 10,
                maxPercentage: 35,
            },
            [SERVICE_DATA_HOLIDAY]: {
                loaded: false,
                percentage: 5,
                maxPercentage: 40,
            },
            [SERVICE_DATA_WORKDAY]: {
                loaded: false,
                percentage: 5,
                maxPercentage: 40,
            },
            [SERVICE_DATA_TIMES]: {
                loaded: false,
                percentage: 60,
                maxPercentage: 99, // slightly less than 100%
                maxValue: 3,
                minValue: 1,
            },
        }

        @Prop({ required: true })
        private appDataLoaded!: any;

        @Watch('appDataLoaded', { deep: true })
        async calculateLoaderPercentage() {
            const { config, appDataLoaded } = this;
            
            for (let key in config) {
                if (appDataLoaded.includes(key)) {
                    if (!this.config[key].loaded) {
                        this.percentage = this.percentage + config[key].percentage
                        this.config[key].loaded = true
                    }
                }
                else {
                    await this.calculateIntermediatePercentage(key)
                }
            }
        }

        async calculateIntermediatePercentage(key: string) {
            const { percentage, maxPercentage, maxValue = 0.2, minValue = 0.1 } = this.config[key];

            const intermidiatePercentage = new Promise((resolve) => {

                if (this.counterKeys.includes(key)) {
                    return resolve({})
                }

                const counterId = setInterval(() => {
                    if (this.config[key].loaded) {
                        this.clearIntervals(counterId, key)
                        return resolve({})
                    }

                    if (this.appDataLoaded.includes(key)) {
                        this.percentage = this.percentage + percentage
                        this.config[key].loaded = true

                        this.clearIntervals(counterId, key)

                        resolve({})
                    }
                    else {
                        const getRandom = () => (Math.random() * (maxValue - minValue) + minValue);
                        const randomized = getRandom();

                        if (maxPercentage > this.percentage + randomized) {
                            this.percentage = this.percentage + randomized
                        }
                    }

                }, 100)       
                
                this.counterIds.push(counterId)
                this.counterKeys.push(key)
            }) 

            return intermidiatePercentage
        }

        clearIntervals(counterId: number, counterKey: string) {
            if (this.counterIds.includes(counterId)) {
                this.counterIds.forEach(id => id === counterId && (clearInterval(counterId)))
            }
            if (this.counterKeys.includes(counterKey)) {
                this.counterKeys = this.counterKeys.filter(key => key !== counterKey)
            }
        }

        handleScroll() {
            this.isFixed = window.scrollY >= 60 /* check --headingMaxHeight css variable */
        }

        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        }

        beforeDestroy() {
            if (this.counterIds.length) {
                this.counterIds.forEach(id => clearInterval(id))
            }

            window.removeEventListener('scroll', this.handleScroll)
        }
    }
