
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component

    export default class ProgressBar extends Vue {
        @Prop({ required: true })
        private percantage!: number;

        @Prop({ default: () => {} })
        private customItemStyles!: any;
    }

