import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import Datepicker from 'vuejs-datepicker';

Vue.use(VTooltip, {
  defaultBoundariesElement: document.body,
});

Vue.component('v-select', vSelect)
Vue.component('datepicker', Datepicker)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
