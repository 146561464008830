
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { ITableDay, ITableTask } from "@/types/time-table";
    import { tasksMsToTime } from "@/utils";
    import dayjs from "dayjs";
    import config, { NO_PROJECT } from "@/config";

    @Component

    export default class TooltipWorkday extends Vue {
        @Prop({ required: true })
        private dayData!: ITableDay;

        get no_project() {
            return NO_PROJECT;
        }

        get tasks(){
            if(this.dayData.currentTask)
                return [...this.dayData.tasks, this.dayData.currentTask];
            return this.dayData.tasks;

        }

        get benchTime() {
            const bench = this.dayData.tasks.filter(t => t.project.isNonBillable)
            return bench && bench.length ? bench : null
        }

        get benchTotalTime() {
            return this.benchTime ? tasksMsToTime(this.benchTime) : null
        }

        get outdo() {
            const duration = this.dayData.duration ? this.dayData.duration.split(':')[0] : 0
            const workDayHours = 8
            return +duration > workDayHours
        }

        get currentUser() {
            return this.$store.state.currentUser
        }

        getEntryTime(task: ITableTask) {
            if (!task.endTime) return false;
            return Number.isInteger(task.duration.totalMinutes) || dayjs(task.lastUpdatedDate).format('HH:mm') !== task.endTime;
        }

        getCreatedDateString(task: ITableTask) {
          return dayjs(task.lastUpdatedDate).format('HH:mm DD.MM.YYYY');
        }

        isTaskRunning(task: ITableTask){
            return !task.endTime;
        }
        isTaskBench(task: ITableTask) {
          return task.project.isNonBillable;
        }

        runningColor(task: ITableTask) {
          if (this.isTaskRunning(task) && this.isTaskBench(task)) {
            return task.project.timesheetColor || config.workloadStyles["--benching"]
          } else if (this.isTaskRunning(task)) {
            return config.workloadStyles['--workload-planner-running']
          }
          return null
        }
        itemDurationColor(task) {
          if (task.project.isNonBillable) {
            return task.project.timesheetColor && task.project.timesheetColor.length ? task.project.timesheetColor : config.workloadStyles["--benching"]
          }
          return null
        }
    }
