
import { Vue, Prop, Component, Watch, Ref } from "vue-property-decorator";
import {
  IChartTimes,
  IChartTrackedTime,
  ITimesProjectUser,
  ITimeUser,
} from "@/types/api";
import ContentPosition from "../ContentPosition/ContentPosition.vue";
import ProjectsInfoTableHeader from "../ProjectsInfoTableHeader/ProjectsInfoTableHeader.vue";
import ProjectsInfoTablePosition from "../ProjectsInfoTablePosition/ProjectsInfoTablePosition.vue";
import ProjectsInfoTableCol from "../ProjectsInfoTableCol/ProjectsInfoTableCol.vue";

import dayjs from "dayjs";
import {
  getProjectsInfoPositionHours,
  getProjectsInfoSumOfDate,
  getWorkingDays,
  getFormattedTime,
} from "@/utils/dateHelper";
@Component({
  components: {
    ContentPosition,
    ProjectsInfoTableHeader,
    ProjectsInfoTablePosition,
    ProjectsInfoTableCol,
  },
})
export default class ProjectsInfoTable extends Vue {
  @Prop()
  projectsHeader!: HTMLDivElement;

  @Prop({ required: true })
  public groupMembers!: {
    position: string;
    users: {
      user: ITimeUser;
      trackedTimes: IChartTrackedTime[];
    }[];
  }[];

  @Prop({ required: true })
  public months!: {
    months: {
      isStartOfTheYear: boolean;
      startDate: Date;
      endDate: Date;
    }[];
    year: number;
  }[];

  @Prop()
  chartScroll!: HTMLDivElement;

  @Ref("tableRef")
  tableRef!: HTMLDivElement;

  @Ref("tableFixedRef")
  tableFixedRef!: HTMLDivElement;

  @Ref("usersScroll")
  usersScroll!: HTMLDivElement;

  @Ref("tableScroll")
  tableScroll!: HTMLDivElement;

  handleBodyScroll() {
    const tableFixedRef = this.tableFixedRef;
    const tableRef = this.tableRef;
    if (tableRef && tableFixedRef) {
      const { y } = tableRef.getBoundingClientRect();
      if (y < 0) {
        tableFixedRef.style.top = `0`;
        tableFixedRef.style.position = "fixed";
        tableFixedRef.style.overflow = "auto";
        tableFixedRef.style.width = "100%";
        tableFixedRef.style.paddingRight = "20px";
        tableFixedRef.scrollLeft = this.chartScroll.scrollLeft;
      } else {
        tableFixedRef.style.position = "sticky";
        tableFixedRef.style.overflow = "unset";
        tableFixedRef.style.width = "auto";
      }
    }
  }

  mounted() {
    window.addEventListener("scroll", this.handleBodyScroll);
  }

  unmounted() {
    window.removeEventListener("scroll", this.handleBodyScroll);
  }

  get members() {
    const columnData = this.groupMembers.map(({ users, position }) => {
      const columnUsers = users.map(({ user, trackedTimes }) => {
        const tracker = this.months
          .map(({ year, months }) => {
            const trackerYear = months.map((month) => {
              const currentMonth = trackedTimes.filter(
                (time) => time.monthDate === month.startDate && time.duration
              );
              if (currentMonth && currentMonth.length > 0) {
                var totlaMillesecondsTrackedTimes = currentMonth.reduce(
                  (acc, curr) => (acc += curr.duration.totalMilliseconds),
                  0
                );
                const workMonth = dayjs(month.startDate).get("month");
                const workDays = getWorkingDays(year, workMonth);
                const totalWorkHoursInMonth = workDays * 8;
                const totalHoursTrackedTimes =
                  totlaMillesecondsTrackedTimes / 1000 / 60 / 60;
                const workedPercent = Math.round(
                  (Math.round(totalHoursTrackedTimes) / totalWorkHoursInMonth) *
                    100
                );
                return {
                  ...month,
                  trackerMonth: currentMonth,
                  formattedHours: getFormattedTime(
                    totlaMillesecondsTrackedTimes
                  ),
                  style: {
                    backgroundImage:
                      workedPercent < 50
                        ? `linear-gradient(to top, rgb(167, 191, 233) ${workedPercent}%, rgb(167, 191, 233) ${workedPercent}%, transparent ${workedPercent}%, transparent ${workedPercent}%)`
                        : `linear-gradient(to top, rgb(134, 159, 202) ${workedPercent}%, rgb(134, 159, 202) ${workedPercent}%, transparent ${workedPercent}%, transparent ${workedPercent}%)`,
                  },
                };
              }
              return null;
            });

            return {
              year,
              trackerYear,
            };
          })
          .map(({ trackerYear }) => trackerYear)
          .flat();

        const totalMilleseconts = tracker
          .filter((it) => !!it)
          .reduce(
            (acc, curr) =>
              curr === null
                ? (acc += 0)
                : (acc += curr.trackerMonth.reduce(
                    (total, month) =>
                      (total += month.duration.totalMilliseconds),
                    0
                  )),
            0
          );
        const totalFormattedHours = getFormattedTime(totalMilleseconts);
        const totalHours = Math.round(totalMilleseconts / 1000 / 60 / 60);

        const totalWorkingHours = this.months
          .map(({ year, months }) => {
            const totalWorkingHours = months.map(({ startDate }) => {
              const workMonth = dayjs(startDate).get("month");
              const workDays = getWorkingDays(year, workMonth);
              const totalWorkHoursInMonth = workDays * 8;
              return totalWorkHoursInMonth;
            });
            return totalWorkingHours;
          })
          .flat()
          .reduce((acc, curr) => (acc += curr), 0);

        const workedPercent = Math.round(
          (+totalHours / totalWorkingHours) * 100
        );
        return {
          user,
          position,
          tracker,
          totalHours: totalFormattedHours,
          style: {
            backgroundImage: `linear-gradient(to top, rgb(226, 235, 232) 100%, rgb(226, 235, 232) 100%, transparent 100%, transparent 100%)`,
          },
        };
      });

      const totalPositionHours = getProjectsInfoPositionHours(
        columnUsers.map((col) => col.totalHours)
      );

      return {
        position,
        users: columnUsers,
        totalDescription: `${position}: ${totalPositionHours} H`,
      };
    });
    return columnData;
  }
}
