import {IReportData, ReportData, IReportUser, IReportRate, IReportGenerate} from "@/types/report";
import {apiGoogle} from "@/api/apiGoogle";
import {ITaskTime, ITimesResponse,ICreateOrUpdateComment} from "@/types/api";
import {apiService} from "@/services/ApiService";

class ReportService {
    async saveToGoogleSheets(report: IReportData[], sheetName: string, startDate: Date, endDate: Date, rateMembers: IReportRate[]): Promise<string> {
        return await apiService.saveToGoogleSheets(report, sheetName, startDate, endDate, rateMembers);
    }

    async getReport(reportId: string): Promise<IReportGenerate> {
        let response = await apiService.getReport(reportId);
        if (response.errorCode !== 0) {
            if (response.errorCode === 2) {
                alert("GoogleSheet tab name duplicate")
            } else if (response.errorCode === 1) {
                alert("GoogleSheet error");
            }

            response.googleSheetUrl = "-1";
        }
        
        if (response.googleSheetUrl === null)
            response.googleSheetUrl = '';
        return response;
    }

    async getReportTimes(startDate: Date, endDate: Date): Promise<ITimesResponse> {
        const positions = [];
        return await apiService.getTimes(startDate, endDate, positions)
            .catch(err => {
                if (err.code === "ECONNABORTED") {
                    alert("Data processing. Please wait for a while...")
                } else {
                    alert("Unknown error");
                }

                return {
                    times: [],
                    projects: [],
                    longCacheUpdatedAt: new Date(),
                    shortCacheUpdatedAt: new Date(),
                    teamCacheUpdatedAt: new Date(),
                    teamPositionsCacheUpdatedAt: new Date()
                };
            });
    }

    async saveRates(report: IReportData[]): Promise<void> {
        const reportRates = report.reduce((all, element) => {
            if (!all.some(item => item.userId == element.user.id && item.projectId == element.projectId)) {
                all.push({
                    projectId: element.projectId,
                    userId: element.user.id,
                    value: element.user.rate
                })
            }

            return all;
        }, [] as IReportRate[]);
        await apiService.saveRates(reportRates);
    }
    
    async saveComment(input: ICreateOrUpdateComment): Promise<void> {        
        await apiService.saveComment(input);
    }

    generate(selectedTasks: ITaskTime[], selectedUsers: IReportUser[]) {
        return selectedTasks.reduce((all, item) => {
            let user = selectedUsers.find(z => z.id === item.user.id) as IReportUser;
            let tempTask = selectedTasks.find(z => z.task.id === item.task.id);
            if (!tempTask) {
                tempTask = selectedTasks.find(z => z.task.id === 0);
            }
            const taskTime = tempTask as ITaskTime;

            let taskStatus = taskTime.task.status.name;
            let taskId = taskTime.task.id;
            let taskName = taskTime.task.name;
            let projectId = taskTime.task.project.id;
            let billable = taskTime.task.tags.every(z => z.name !== "non-billable") ? Number(item.duration.totalMilliseconds) : 0;
            let nonBillable = taskTime.task.tags.some(z => z.name === "non-billable") ? Number(item.duration.totalMilliseconds) : 0;
            if (taskTime.task.parent) {
                taskStatus = taskTime.task.parent.status.name;
                taskId = taskTime.task.parent.id;
                taskName = taskTime.task.parent.name;

                // If parent task non-billable we want to mark subtask as non-billable also.
                // If parent task billable it should depends on subtask tags.
                let parentIsNonBillable = taskTime.task.parent.tags.some(z => z.name === "non-billable");
                if (parentIsNonBillable) {
                    nonBillable = Number(item.duration.totalMilliseconds);
                    billable = 0;
                }
            }
            let data = all.find(z => z.taskId === taskId && z.user.id === item.user.id);
            if (!data) {
                data = new ReportData(0, 0, "", taskId, projectId, taskName, user);
                all.push(data);
            }

            data.status = taskStatus;
            data.billable += billable;
            data.nonBillable += nonBillable;

            return all;
        }, [] as IReportData[])
    }
    //remove loading planning from excel for Brights
    // async accessWorkloadPlannerDataAsync() {
    //     return apiGoogle.accessWorkloadPlannerDataAsync();
    // }

    async accessTeamStatusDataAsync() {
        return apiGoogle.accessTeamStatusDataAsync();
    }
}

export const reportService = new ReportService();
