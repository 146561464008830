
export const HANDLE_APP_DATA_LOADING = (state, { payload: { loading, loaded, error, unauthorized } }) => {
    state.appDataLoading = loading
    state.appDataLoadedSuccess = loaded
    state.appDataLoadedError = error
    state.appDataUnauthorized = unauthorized
}

export const SET_STORE_FIELDS = (state, { payload: { field, value } }) => {
    if (!Array.isArray(field)) field = [field]
    if (!Array.isArray(value)) value = [value]

    field.forEach((f, i) => state[f] = value[i])
}

export const TOGGLE_ACTIVE_POSITION = (state, { payload: { position } }) => {
    const { activePositions } = state;

    if (activePositions.includes(position)) {
        state.activePositions = state.activePositions.filter(p => p !== position)
    }
    else {
        state.activePositions = [...state.activePositions, position]
    }
}

export const SET_ACTIVE_POSITION = (state, { payload: { positions } }) => {
    state.activePositions = positions;
};

export const SET_TIME_CURRENT = (state, { payload: { timesCurrent } }) => {
    state.timesCurrent = timesCurrent;
};

export const ADD_ACTIVE_POSITION = (state, { payload: { position } }) => {
    const { activePositions } = state;

    if (!activePositions.includes(position)) {
        state.activePositions = [...state.activePositions, position]
    }
}

export const TYPE_COMMENT = (state, { payload: { value } }) => {
    state.typingComment = value
}

export const CURRENT_WEEK_USER = (state, { payload: { userId } }) => {
    state.currentWeekUser = userId;
}