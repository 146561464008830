
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import ContentDay from "../ContentDay/ContentDay.vue";
import ContentWeek from "../ContentWeek/ContentWeek.vue";
import { ProgressBar } from "../Shared";
import {
  ISelectedTableWeek, ITableDay,
  ITableMember,
  ITableMonthDay,
  ITableQuarterItem,
  ITableWeek,
} from "@/types/time-table";
import { splitSelectedWeeks, timeToPercent } from "@/utils";
import { IWeekTime, IWorloadPlanner, IProject, ITimePlan, ITimePlanDetails, IPlan } from "@/types/api";
import config from "@/config";
import dayjs from "dayjs";

@Component({
  components: {
    ContentDay,
    ContentWeek,
    ProgressBar,
  },
})
export default class TableContentContainer extends Vue {
  @Prop({ required: true })
  public user!: ITableMember;

  @Prop({ required: true })
  public userIndex!: number;

  @Prop({ required: true })
  private daysInMonth!: ITableMonthDay[];

  @Prop({ required: true })
  private quarter!: ITableQuarterItem[];

  @Prop({ required: true })
  private weekTimes!: IWeekTime[]

  @Prop({ required: true })
  private selectedProjects!: IProject[];

  @Prop({ required: true })
  private selectedWeeksProp!: ISelectedTableWeek[];

  @Prop({ required: true })
  private timePlans!: ITimePlan[];

  public selectedWeeks: ISelectedTableWeek[] = [];

  get userSelectedWeeks() {
    const currentUserId = this.$store.state.currentWeekUser;
    this.selectedWeeks = this.selectedWeeks.filter(({ id }) => id === currentUserId);
    return this.user;
  }

  getWorkloadPercentage({ user, daysRange }) {
    const foundPlan = this.timePlans.find(({ startDate }) => dayjs(startDate).locale('en').format('D-MMM') === daysRange);

    if (foundPlan) {
      const plans = foundPlan.plans[user.id] || [];
      const workloadPercent = timeToPercent(plans.reduce((acc, curr) => acc += curr.totalMilliseconds, 0) || 0);
      return workloadPercent;
    }
    return '0%';    
  }

  @Emit("showDayDetails")
  showDayDetailsData(day, user, daysGroup) {
    return { day, user, daysGroup };
  }

  @Emit("showWeekDetails")
  showWeekDetailsData(week, user, event: KeyboardEvent, index) {
    const data = {
      week,
      user,
      event,
      index,
    }

    const currentWeekTime = this.weekTimes.find((weekTime) => weekTime.startDate === week.startDate && week.endDate === weekTime.endDate)
    const weekTimeProjects =
      currentWeekTime &&
      currentWeekTime.usersTimes &&
      currentWeekTime.usersTimes[data.user.id] &&
      currentWeekTime.usersTimes[data.user.id].projectsTimes;

    const currentWeek = { ...week, projectsTimes: weekTimeProjects || week.projectsTimes, selectedIndex: index, id: user.id };
    const alreadySelected = this.selectedWeeks.find(({ id, selectedIndex }) => id === user.id && selectedIndex === index);
    const selectedWeeks = [...this.selectedWeeks, currentWeek]
      .sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate))
      .map((week, index) => ({
        ...week,
        selectedIndex: index,
        id: user.id
      }));

    const userWeeks = user.weeks.map(((userWeek, index) => {
      const weekTime = this.weekTimes.find((weekTime) => weekTime.endDate === userWeek.endDate && weekTime.startDate === userWeek.startDate);
      const projectsTimes =
        weekTime &&
        weekTime.usersTimes &&
        weekTime.usersTimes[data.user.id] &&
        weekTime.usersTimes[data.user.id].projectsTimes
        || userWeek.projectsTimes;

      return {
        ...userWeek,
        selectedIndex: index,
        id: user.id,
        projectsTimes
      }
    }));

    if (this.selectedWeeks.length === 1 && alreadySelected) {
      this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: null }});
      this.selectedWeeks = [];
      return null;
    }
    
    if (alreadySelected && !(!event.metaKey || !event.ctrlKey)) {
      this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: user.id }});
      this.selectedWeeks = [currentWeek];
      return {
        ...data,
        selectedWeeks: [currentWeek],
        // workloadDataWeeks: [currentWeek].map((week) => this.getUserWorkloadDataFromWeek(week))
      }
    }
    
    if (alreadySelected && (event.metaKey || event.ctrlKey)) {
      const currentSelectedWeeksIndex = selectedWeeks.findIndex((selectedWeek) => selectedWeek.startDate === currentWeek.startDate);
      const quarters = this.quarter.map(({ weeks }) => weeks || []).flat();
      const weeks = splitSelectedWeeks(selectedWeeks, currentSelectedWeeksIndex).map((selectedWeek) => {
        const selectedIndex = quarters.findIndex((quarter) => quarter.startDate === selectedWeek.startDate && quarter.endDate === selectedWeek.endDate);
        return {
          ...selectedWeek,
          selectedIndex
        };
      });

      this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: user.id }});
      this.selectedWeeks = weeks;
      return {
        ...data,
        selectedWeeks: weeks
      }
    }

    if (!alreadySelected && (event.metaKey || event.ctrlKey)) {
      const filteredWeeks = selectedWeeks.filter(({ id, selectedIndex }) => id === user.id && selectedIndex !== index);
      const weeks = [...filteredWeeks, currentWeek].sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));

      if (weeks.length > 1) {
        const startDateIndex = user.weeks.findIndex((userWeek) => userWeek.startDate === weeks[0].startDate);
        const currentWeekIndex = user.weeks.findIndex((userWeek) => userWeek.endDate === weeks[weeks.length - 1].endDate);
        const userSlicedWeeks = currentWeekIndex < startDateIndex ?
        userWeeks.slice(currentWeekIndex, startDateIndex + 1) :
        userWeeks.slice(startDateIndex, currentWeekIndex + 1);
        // const workloadDataWeeks = userSlicedWeeks.map((week) => this.getUserWorkloadDataFromWeek(week));     
        this.selectedWeeks = userSlicedWeeks;
        this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: user.id }});
        return {
          ...data,
          selectedWeeks: userSlicedWeeks
        }
      } else {
        this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: user.id }});
        this.selectedWeeks = [currentWeek];
        return {
          ...data,
          selectedWeeks: [currentWeek]
        }
      }

    }
    this.$store.commit('CURRENT_WEEK_USER',{ payload: { userId: user.id }});
    this.selectedWeeks = [currentWeek];
    return {
      week,
      user,
      event,
      index,
      selectedWeeks: [currentWeek],
    };
  }

  @Emit("showPlanned")
  showWorkloadPlannerData(group, user, daysRange) {
    return { user, daysRange };
  }

  get view() {
    return this.$store.state.timeTableView;
  }

  get timeTableMonth() {
    return this.$store.state.timeTableMonth;
  }

  get timeTableYear() {
    return this.$store.state.timeTableYear;
  }

  get groupDaysByWeekdays() {
    const days = this.user.days;
    const daysWithWeekends = Object.keys(days).map(day => {
      if (!day) return

      const dayIndex = +day - 1;
      const dayDate = new Date(this.timeTableYear, this.timeTableMonth, +day);
      const monthDay = this.daysInMonth[dayIndex] || {};

      return {
        ...days[day],
        weekend: monthDay.weekend || false,
        dayInMonth: day,
        weekday: dayDate.toLocaleString("en-GB", { weekday: "long" }),
        fullDate: dayDate,
        holiday: monthDay.holiday,
        workday: monthDay.workday,
      };
    });

    const currentMonthAbbreviation = new Date(this.timeTableYear, this.timeTableMonth, 1)
        .toDateString()
        .split(" ")[1];

    const month: any[] = [];
    let current = { isWeekend: false, days: [] as ITableDay[], daysRange: '' };

    daysWithWeekends.forEach((day, index, allDays) => {
      if (!day) return
      const shouldGroupEnd = current.isWeekend !== day.weekend ||
          (day.weekend ? current.days.length > 1 : current.days.length > 4);

      if (shouldGroupEnd && current.days.length) {
        if (!current.isWeekend && current.days.length < 5) {
          const daysBefore = 5 - current.days.length;
          const firstDayOfMonth = new Date(this.timeTableYear, this.timeTableMonth, 1);
          const previousMonthWeekday = new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - daysBefore))
              .toDateString()
              .split(" ");
          current.daysRange = `${previousMonthWeekday[2]}-${previousMonthWeekday[1]}`;
        }
        month.push(current);
        current = { isWeekend: false, days: [], daysRange: '' };
      }

      //@ts-ignore
      current.days.push(day);
      current.isWeekend = day.weekend;

      if (!current.isWeekend && !current.daysRange) {
        current.daysRange = `${day.dayInMonth}-${currentMonthAbbreviation}`;
      }

      if (index === allDays.length - 1) {
        month.push(current);
      }
    });

    return month;
  }

  getWorkloadScoreClassName(scores) {
    const total = parseInt(scores);
    const percentage = (p) =>
        config.workloadStyles[`--total-time-planner-${p}`];
        
    if (total > 0 && total <= 30) {
      return percentage(0);
    }
    if (total > 30 && total <= 80) {
      return percentage(30);
    }
    if (total > 80 && total <= 100) {
      return percentage(80)
    }
    if (total > 100) {
      return percentage(100)
    }
  }

  getUserWorkloadDataFromWeek(week: ITableWeek) {
    if (!week || !this.user.id) return [];
    let currUserPlans = this.timePlans.map(item => ({
      startDate: item.startDate,
      endDate: item.endDate,
      plans: item.plans[this.user.id]
    }) as ITimePlanDetails);

    let currentWorkloadData: IPlan[] = [];

    if (currUserPlans && currUserPlans.length) {
      currUserPlans.forEach((item: ITimePlanDetails) => {
        if (item.plans && item.plans.length) {
          let projects: IPlan[] = [...item.plans]

          if (this.selectedProjects.length) {
            projects = projects.filter(project => {
              return this.selectedProjects.some(sp => sp.name.toLowerCase() === project.projectName.toLowerCase())
            })
          }

          // TODO: check by ranges
          const hasAllNoInfo = item.plans.every(
              (_) => _.totalMilliseconds === 0
          );

          if (!hasAllNoInfo) {
            currentWorkloadData = [
              ...currentWorkloadData,
              ...projects,
            ];
          }
        }
      });
    }


    return currentWorkloadData;
  }
}
