import creds from "../../google-creds.json";
import {GoogleSpreadsheet} from 'google-spreadsheet'
import config, { teamStatusConnectionConstants } from '../config'

class ApiGoogle {
	WPSheetId: number;
	spreadsheetId: string;
	availabilityWPSheetId: number;
	availabiliytSheetId: string;

	constructor() {
		this.WPSheetId = 1829069320;
		this.spreadsheetId = '1rvz6qLi3mJ6_PSswMvgCIWhVLKbILsDRcMuoEvthxGw';

		this.availabilityWPSheetId = 823254240;
		this.availabiliytSheetId = '1jfZfKCegeGE7KkA4jBFnlznReYUE7_CC3SPrC4lMsgU';
	}

	async loadSheetRows(spreadsheetId, gid, config = {}) {
		const doc = new GoogleSpreadsheet(spreadsheetId);
		await doc.useServiceAccountAuth(creds);
		await doc.loadInfo();

		const sheet = await doc.sheetsById[gid]

		return await sheet.getRows(config)
	}
	
	//remove loading planning from excel for Brights
	// async accessWorkloadPlannerDataAsync() {
		//const sheetInfo = await this.loadSheetRows(this.spreadsheetId, this.WPSheetId, { offset: 1 })
		//const workloadPlanner = {}

		// sheetInfo.forEach(row => {
		// 	const {Employee, Project} = row

		// 	if (Employee) {
		// 		for (const key in row) {
		// 			if (!workloadPlanner[Employee]) {
		// 				workloadPlanner[Employee] = {}
		// 			}
		// 			workloadPlanner[Employee]
		// 			if (/\d-\w+/.test(key)) {
		// 				if (!workloadPlanner[Employee][key]) {
		// 					workloadPlanner[Employee][key] = []
		// 				}
		// 				// no time info is given for project
		// 				if (row[key]) {
		// 					workloadPlanner[Employee][key].push({Project, workload: row[key]})
		// 				} else {
		// 					workloadPlanner[Employee][key].push({Project, workload: 'no info'})
		// 				}
		// 			}
		// 		}
		// 	}
		// })

		// return workloadPlanner
	// }

	//not using status of connection
	async accessTeamStatusDataAsync() {
		// const sheetInfo = await this.loadSheetRows(this.availabiliytSheetId, this.availabilityWPSheetId)

		// const columnNames = { ...config.teamStatus.columnNames }

		// if (!sheetInfo) return {};

		// return sheetInfo.reduce((res, curr) => {
		// 	if (curr[columnNames.name] && curr[columnNames.availability] && curr[columnNames.connection]) {
		// 		const name = curr[columnNames.name].trim()
		// 		const availabilityPercent = curr[columnNames.availability]

		// 		let connection = ''

		// 		switch(curr[columnNames.connection].toLowerCase().trim()) {
		// 			case teamStatusConnectionConstants.TEAM_STATUS_LOW_CONNECTION:
		// 				connection = teamStatusConnectionConstants.TEAM_STATUS_LOW_CONNECTION
		// 				break;
		// 			case teamStatusConnectionConstants.TEAM_STATUS_HAS_CONNECTION:
		// 				connection = teamStatusConnectionConstants.TEAM_STATUS_HAS_CONNECTION
		// 				break;
		// 			case teamStatusConnectionConstants.TEAM_STATUS_NO_CONNECTION:
		// 				connection = teamStatusConnectionConstants.TEAM_STATUS_NO_CONNECTION
		// 				break;
		// 		}

		// 		res[name] = { availability: parseInt(availabilityPercent), connection}
		// 	}
		// 	return res
		// }, {})

		return {}
	}
}

export const apiGoogle = new ApiGoogle();
