export class TokenService {
    private token: string;

    constructor() {
        this.token = localStorage.getItem('access_clickup_token') || '';
    }

    get accessToken() {
        return this.token;
    }

    setAccessToken(token) {
        this.token = token;
        localStorage.setItem('access_clickup_token', this.token);
    }
}

export const tokenService = new TokenService();
