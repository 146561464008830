
import { Component, Prop, Vue, Emit  } from 'vue-property-decorator';
import { IDayData, IProject, ITimePlan } from '@/types/api';
import {ITableMember, ITableMode, ISelectedWeek} from '@/types/time-table';
import { formatDate } from '@/utils';
import PlannerInfo from '../PlannerInfo/PlannerInfo.vue';
import WeekInfo from '../WeekInfo/WeekInfo.vue';
import TooltipVacation from '../TooltipVacation/TooltipVacation.vue';
import TooltipWorkday from '../TooltipWorkday/TooltipWorkday.vue';
import AverageTime from '../AverageTime/AverageTime.vue';
import NoteDay from '../NoteDay/NoteDay.vue';

@Component({
  components: {
    AverageTime,
    PlannerInfo,
    TooltipVacation,
    TooltipWorkday,
    NoteDay,
    WeekInfo
  },
})

export default class DetailsView extends Vue {
  private timeFormat: any = { day: "2-digit", month: "2-digit" };
  scrolled: boolean = false;

  @Prop({ required: true })
  private allProjects!: IProject[];

  @Prop({ required: true })
  private selectedWeeks!: ISelectedWeek[];

  // @Prop({ required: true })
  // private userSelectedWeeks!: IUserSelectedWeeks[];

  @Prop({ required: true })
  private timePlansDetails!: ITimePlan[];

  // @Prop({ required: true })
  // private plannerData!: [];

  @Prop({ required: true })
  private dayData!: IDayData;

  @Prop({ required: true })
  private weekData!: any;

  @Prop()
  private userDetails!: ITableMember;

  @Prop()
  private appView!: ITableMode;

  @Emit('updComments')
  updComments(data: { dayData: IDayData, userDeteils: ITableMember}) {
    return data;
  }

  get currentUser() {
    return this.$store.state.currentUser
  }

  get isBenching() {
    return this.dayData && (this.dayData.benchingPercent && !this.dayData.duration && !this.dayData.vacation)
  }

  get dataExists() {
    return this.timePlansDetails.length || this.dayData || this.weekData;
    // return (this.plannerData && this.plannerData.length) ||
    //     this.dayData && (this.dayData.duration || this.dayData.vacation || this.dayData.sickDay) ||
    //     this.dayData && this.isBenching
  }

  get userInfoExists() {
    return this.userDetails && this.userDetails.name && this.userDetails.position
  }

  get timeRange() {
    if (!this.dayData) return null
    const { vacation, sickDay, fullDate, weekday } = this.dayData;
    const term = vacation || sickDay;

    if (term && term.startDate && term.endDate) {
      const { startDate, endDate } = term
      const sFormatted = formatDate({ date: new Date(startDate), ...this.timeFormat })

      if (startDate === endDate) {
        return sFormatted
      }

      const eFormatted = formatDate({ date: new Date(endDate), ...this.timeFormat })
      return `${sFormatted} - ${eFormatted}`
    }
    if (fullDate && weekday) {
      const dFormatted = formatDate({ date: fullDate, year: "numeric", ...this.timeFormat })
      return `${dFormatted} ${weekday || ''}`
    }
    return null
  }

  get selectedWeeksDetails() {
    return this.selectedWeeks;
  }
  
  @Emit('changeUserProject')
  changeUserProject(project) {
    return { ...project, userDetails: this.userDetails };
  }

  @Emit('createUserProject')
  async createUserProject(formData) {
    return {formData, userDetails: this.userDetails};
  }  

  handleScroll() {
    const header = document.querySelector<HTMLElement>(".header")
    const headerHeight = header!.offsetHeight

    this.scrolled = window.scrollY > headerHeight;
  }
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  }
}
