import { IFormattedDate, IWeekTime, IHoliday, IWorkday } from "@/types/api";
import { ITableMonthDay, ITableQuarterItem } from "@/types/time-table";
import { IMonth, MonthsArray } from "@/types/months";
import { clamp } from "./commonHelper";

export function getNumberOfDays(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

export function formatDate({
  date,
  format = "ru",
  day,
  month,
  year,
}: IFormattedDate) {
  const config = { day, month, year };

  for (let key in config) {
    if (!config[key]) delete config[key];
  }
  return date.toLocaleString(format, { ...config });
}

export function getTotalDaysRange(
  start: Date,
  end: Date,
  currentMonth: number
): number[] {
  const daysRange: number[] = [];

  const startDateMonth = start.getMonth();
  const endDateMonth = end.getMonth();

  const startDate = start.getDate();
  const endDate = end.getDate();

  if (startDateMonth === endDateMonth) {
    const numberOfDays = endDate - startDate + 1; // start date is included, so 1+ day
    Array.from({ length: numberOfDays }, (_, x) => endDate - x).forEach((d) =>
      daysRange.push(d)
    );
  } else if (startDateMonth !== endDateMonth && currentMonth === endDateMonth) {
    Array.from({ length: endDate }, (_, x) => ++x).forEach((d) =>
      daysRange.push(d)
    );
  } else if (
    startDateMonth !== endDateMonth &&
    currentMonth === startDateMonth
  ) {
    const lastMonthDay = new Date(
      start.getFullYear(),
      start.getMonth(),
      0
    ).getDate();
    const numberOfDays = lastMonthDay - startDate;
    Array.from({ length: numberOfDays }, (_, x) => startDate + x).forEach((d) =>
      daysRange.push(d)
    );
  }

  return daysRange;
}

export function getMonthWeekends(
  month,
  year,
  holidays: IHoliday[],
  workdays: IWorkday[]
): number[] {
  const weekends: number[] = [];
  const numberOfDays = getNumberOfDays(year, month);

  Array.from({ length: numberOfDays }, (_, x) => {
    const day = ++x;
    const newDate = new Date(year, month, day);
    if (
      (newDate.getDay() === 0 ||
        newDate.getDay() === 6 ||
        holidays.some(
          (w) =>
            newDate >= new Date(w.startDate!) && newDate <= new Date(w.endDate!)
        )) &&
      !workdays.some(
        (w) =>
          newDate >= new Date(w.startDate!) && newDate <= new Date(w.endDate!)
      )
    ) {
      weekends.push(day);
    }
  });
  return weekends;
}

export function getMonthDays(
  month,
  year,
  holidays: IHoliday[],
  workdays: IWorkday[]
): ITableMonthDay[] {
  const currentDateMaybe = (day) => {
    return (
      new Date([month + 1, day, year].join("/")).toLocaleDateString() ===
      new Date().toLocaleDateString()
    );
  };
  const numberOfDays = getNumberOfDays(year, month);
  const weekends: number[] = getMonthWeekends(month, year, holidays, workdays);

  return Array.from({ length: numberOfDays }, (_, x) => {
    const day = ++x;
    return {
      number: day,
      weekend: weekends.includes(day),
      isToday: currentDateMaybe(day),
      weekday: new Date(year, month, x).toLocaleString("en-GB", {
        weekday: "short",
      }),
    };
  });
}

export function getQuarterFromWeekTimes(
  weekTimes: IWeekTime[]
): ITableQuarterItem[] {
  if (!weekTimes || !weekTimes.length) return [];

  const now = new Date();

  const QUARTER_MONTH_COUNT = 3;
  let monthNumber = +(new Date(weekTimes[0].endDate).getMonth() - 1); // End date of the first week, should always ends at current month

  const quarter: ITableQuarterItem[] = new Array(QUARTER_MONTH_COUNT)
    .fill({ month: null, weeks: [] })
    .map(() => {
      monthNumber = monthNumber === 11 ? 0 : monthNumber + 1;
      return { month: MonthsArray[monthNumber], weeks: [] };
    });

  let lastQuarterIdx = 0;
  weekTimes.forEach((item) => {
    if (
      +new Date(item.endDate).getMonth() !== +quarter[lastQuarterIdx].month.id
    ) {
      lastQuarterIdx = clamp(lastQuarterIdx + 1, 0, QUARTER_MONTH_COUNT - 1);
    }

    const isCurrent =
      now.getTime() >= new Date(item.startDate).getTime() &&
      now.getTime() <= new Date(item.endDate).getTime();

    quarter[lastQuarterIdx].weeks.push({ ...item, isCurrent });
  });

  return quarter;
}

export function getQuarterByNumberOfWeeks(
  month,
  year,
  numberOfWeeks = 15
): ITableQuarterItem[] {
  if (numberOfWeeks < 1) return [];

  const WEEK_DAYS_COUNT = 7;
  const QUARTER_MONTHES_COUNT = 3;

  const today: Date = new Date();

  const firstDateOfMonth: Date = new Date(year, month, 1);

  let mondayDateAcc: Date = getMondayOfWeekFromDate(firstDateOfMonth);
  let sundayDateAcc: Date = addDays(mondayDateAcc, 6);

  const quarter: ITableQuarterItem[] = [];
  const monthesInQuarter: IMonth[] = Array.from(
    { length: QUARTER_MONTHES_COUNT },
    (_, idx) =>
      MonthsArray.filter(
        (m) => m.id === new Date(year, month + idx).getMonth()
      )[0]
  );

  for (let month of monthesInQuarter) {
    quarter.push({ month, weeks: [] });
  }

  let currentQuarterItemIdx: number = 0;

  for (let week: number = 0; week < numberOfWeeks; week++) {
    const startDate: Date = new Date(mondayDateAcc);
    const endDate: Date = new Date(sundayDateAcc);
    const isCurrent: boolean = today >= startDate && today <= endDate;

    const weekItem: IWeekTime = {
      startDate,
      endDate,
      isCurrent,
    };

    if (
      quarter[currentQuarterItemIdx].weeks.length > 0 &&
      weekItem.startDate.getMonth() !==
        quarter[currentQuarterItemIdx].month.id &&
      currentQuarterItemIdx + 1 < quarter.length
    ) {
      currentQuarterItemIdx++;
    }

    quarter[currentQuarterItemIdx].weeks.push(weekItem);

    mondayDateAcc = addDays(mondayDateAcc, WEEK_DAYS_COUNT);
    sundayDateAcc = addDays(sundayDateAcc, WEEK_DAYS_COUNT);
  }

  return quarter;
}

export function getMondayOfWeekFromDate(date: Date) {
  const _date = new Date(date);
  const day = _date.getDay();
  const diff = _date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(_date.setDate(diff));
}

export function getSundayOfWeekFromDate(date: Date) {
  const _date = new Date(date);
  const day = _date.getDay();
  const diff = _date.getDate() - day;
  return new Date(_date.setDate(diff));
}

export function addDays(date, days): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const getProjectsInfoSumOfDate = (hours: string[]) => {
  const totalMinutes = hours.reduce((acc, currentTime) => {
    const [hours, minutes] = currentTime.split(":");
    acc += parseInt(hours) * 60 + parseInt(minutes);
    return acc;
  }, 0);

  const totalHours = Math.floor(totalMinutes / 60);
  return `${totalHours}:${totalMinutes % 60}`;
};

export const getProjectsInfoPositionHours = (hours: string[]) => {
  const totalMinutes = hours.reduce((acc, currentTime) => {
    const [hours, minutes] = currentTime.split(":");
    acc += parseInt(hours) * 60 + parseInt(minutes);
    return acc;
  }, 0);
  return Math.round(totalMinutes / 60);
};

export const getFormattedTime = (totalMilliseconds: number) => {
  const totalHoursTrackedTimes = totalMilliseconds / 1000 / 60 / 60;
  return (
    Math.round(totalHoursTrackedTimes) +
    ":" +
    Math.round(
      (totalHoursTrackedTimes - Math.round(totalHoursTrackedTimes)) * 60
    )
  );
};

export const getWorkingDays = (year: number, month: number) => {
  const lastDayOfMonth = new Date(year, month, 0);
  const totalDaysInMonth = lastDayOfMonth.getDate();
  let workingDaysCount = 0;

  for (let day = 1; day <= totalDaysInMonth; day++) {
    const currentDate = new Date(year, month - 1, day);
    const dayOfWeek = currentDate.getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      workingDaysCount++;
    }
  }

  return workingDaysCount;
};
