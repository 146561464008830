<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_3483_262530)">
            <path d="M12 4L4 12" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_3483_262530">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'IconClose'
}
</script>

<style scoped></style>