
    import IconEdit from '@/components/Icons/IconEdit.vue'
    import IconTrash from '@/components/Icons/IconTrash.vue'
    import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
    import { IDayData, ICreateOrUpdateComment, IComment } from "@/types/api";
    import { ITableMember } from '@/types/time-table';

    @Component({
        components: { IconEdit, IconTrash }
    })

    export default class NoteDay extends Vue {
        @Prop({ required: true })
        private dayData!: IDayData;

        @Prop({ required: true })
        private userDetails!: ITableMember;

        @Emit('updComments')
        updComments(dayData: IDayData, userDeteils: ITableMember) {
            return { dayData: dayData, userDeteils: userDeteils};
        }

        @Watch('dayData')
        clearComponent() {
            this.comment = ''
        }

        private sendingMessage: boolean = false;
        private deletingMessageId: string | number | null = null;
        private comment: string = '';
        private isEditing: boolean = false;

        get statusName() {
            return this.dayData.vacation
                ? this.dayData.vacation.status.name :
                this.dayData.sickDay
                    ? this.dayData.sickDay.status.name : ''
        }

        get currentUser() {
            return this.$store.state.currentUser
        }

        get currentDate() {
            return new Date(this.dayData.fullDate);
        }
        
        get selectedDay() {
            return this.currentDate.getDate();  
        } 

        get selectedMonth() {
            return this.currentDate.getMonth();  
        } 
        
        get selectedYear() {
            return this.currentDate.getFullYear();  
        }
        
        get commentsExict() {
            return !this.dayData.comments || 
                ((!this.currentUser.isHR || (this.currentUser.isHR && this.currentUser.id == this.userDetails.id)) && !this.dayData.comments.some(comment => comment.userWhoLeftNote.id == this.currentUser.id))  || 
                (this.currentUser.isHR && this.currentUser.id != this.userDetails.id && !this.dayData.comments.some(comment => comment.isHR))
        }

        keyupEnterHandler(e, existComment) {
            if (e.shiftKey || e.altKey || e.ctrlKey) return
            existComment ? this.saveComment(existComment) : this.createComment()
        }

        async createComment(): Promise<void>
        {
            if (!this.comment) return
            this.sendingMessage = true
            let input: ICreateOrUpdateComment = {id: undefined, userId: this.userDetails.id, date: new Date(Date.UTC(this.selectedYear, this.selectedMonth, this.selectedDay)), comment: this.comment}
            await this.$store.dispatch("SaveComment", {input: input});
            await this.setComments();

            this.typeMessage(false)
            this.comment = '';
            this.sendingMessage = false;
        }

        async saveComment(item: IComment): Promise<void>
        {
            this.sendingMessage = true
            let input: ICreateOrUpdateComment = {id: item.id, userId: item.user.id, date: item.date, comment: item.text}
            await this.$store.dispatch("SaveComment", {input: input});
            await this.setComments();

            this.typeMessage(false)
            this.sendingMessage = false;
        }

        editOnOff(item: IComment): void{
            item.isEditing = !item.isEditing
            this.$forceUpdate()
        }

        async deleteComment(item: IComment): Promise<void>
        {
            item.isEditing = false;
            this.deletingMessageId = item.id;
            await this.$store.dispatch("DeleteComment", {id: item.id});
            await this.setComments();
            this.deletingMessageId = null;
        }

        async setComments() {
            let dayData = this.dayData;
            var comments: IComment[] = await this.$store.dispatch("GetComments", {
                startDate: new Date(Date.UTC(this.selectedYear, this.selectedMonth, this.selectedDay)),
                endDate: new Date(Date.UTC(this.selectedYear, this.selectedMonth, this.selectedDay)),
                removeLoaderFlag: false
            });

            dayData.comments = comments.filter(comment => comment.user.id == this.userDetails.id);
            this.updComments(this.dayData, this.userDetails);

            this.$forceUpdate()
        }

        typeMessage(value, message = null) {
            if (message && !value) return;

            this.$store.commit({
                type: "TYPE_COMMENT",
                payload: {
                    field: ['typingComment'],
                    value: value,
                }
            })
        }
    }
