var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.daysInMonthDataUpd,staticClass:"table-header-sticky"},[(_vm.view.value === 'month')?_c('div',{staticClass:"table-header-item table-header-item--month table-item"},_vm._l((_vm.daysInMonth),function(dayItem){return _c('div',{key:`${dayItem.number}--day`,staticClass:"table-header table-header--month flex center",class:{
        workday: !dayItem.weekend && !dayItem.holiday || dayItem.workday,
        today: dayItem.isToday,
        holiday: dayItem.holiday,
      },attrs:{"title":dayItem.holiday && dayItem.holiday.name}},[_c('div',{staticClass:"table-header-content flex center"},[_vm._v(_vm._s(dayItem.number))]),_c('div',{staticClass:"table-header-weekday"},[_vm._v(_vm._s(dayItem.weekday))])])}),0):(_vm.view.value === 'quarter')?_c('div',{staticClass:"table-header table-header-item table-header-item--quarter table-item"},_vm._l((_vm.quarter),function(quarterItem){return _c('div',{key:`${quarterItem.month.id}--month`,staticClass:"table-header--quarter",attrs:{"title":quarterItem.month.name}},[_c('div',{staticClass:"table-header-title"},[_vm._v(_vm._s(quarterItem.month.name))]),_c('div',{staticClass:"table-header-weeks rel flex"},_vm._l((quarterItem.weeks),function(weekItem){return _c('div',{key:weekItem.from,staticClass:"table-header-week flex center",class:{
            current: weekItem.isCurrent,
          }},[_c('span',{staticClass:"table-header-week__from"},[_vm._v(_vm._s(new Date(weekItem.startDate).getDate()))]),_vm._v(" - "),_c('span',{staticClass:"table-header-week__to"},[_vm._v(_vm._s(new Date(weekItem.endDate).getDate()))])])}),0)])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }