import {apiClient} from "@/services/ApiClient";
import { CurrentUser, ITimesResponse, ITaskTime, IChartTimes, IVacation, ISickDay, ITeam, IWeekTime, IAuth, IHoliday, IWorkday, ICreateOrUpdateComment, IComment, IProject, ITimePlan } from "@/types/api";
import {IReportData, IReportGenerate, IReportRate} from "@/types/report";

class ApiService {
    async getAccessToken(code: string): Promise<IAuth> {
        let response = await apiClient.get<IAuth>(`click-up/auth?code=${code}`);
        return response.data;
    }

    async getTeam(): Promise<ITeam[]> {
        let response = await apiClient.get<ITeam[]>('click-up/team');
        return response.data;
    }

    async getRates(): Promise<IReportRate[]> {
        let response = await apiClient.get<IReportRate[]>('report/rates');
        return response.data;
    }

    async getVacations(): Promise<IVacation[]> {
        let response = await apiClient.get<IVacation[]>('click-up/vacations');
        return response.data;
    }

    async getSickDays(): Promise<ISickDay[]> {
        let response = await apiClient.get<ISickDay[]>('click-up/sick-days');
        return response.data;
    }

    async getHolidays(): Promise<IHoliday[]> {
        let response = await apiClient.get<IHoliday[]>('click-up/holidays');
        return response.data;
    }

    async getWorkdays(): Promise<IWorkday[]> {
        let response = await apiClient.get<IWorkday[]>('click-up/workdays');
        return response.data;
    }

    async getCurrentUser(): Promise<CurrentUser> {
        let response = await apiClient.get<CurrentUser>('click-up/user');
        return response.data;
    }

    async getTimes(startDate: Date, endDate: Date, positions: string[]): Promise<ITimesResponse> {
        const positionsQueryParam = positions && positions.length ? positions.reduce((query, position) => {
            query = query + `&Positions=${position}`
            return query
        }, '') : '';

        const response = await apiClient.get<ITimesResponse>(`click-up/times?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&offset=${-startDate.getTimezoneOffset()}${positionsQueryParam}`);
        return response.data;
    }

    async getTimesCurrent(): Promise<ITaskTime[]> {
        const response = await apiClient.get<ITaskTime[]>(`click-up/times_current`);
        return response.data;
    }

    async getTimesChart(projectIds: number[], userIds: number[]): Promise<IChartTimes> {
        const projectQueryParam = projectIds && projectIds.length ? projectIds.reduce((query, projectId) => {
            query = query + `&projectIds=${projectId}`
            return query
        }, '') : '';

        const userQueryParam = userIds && userIds.length ? userIds.reduce((query, userId) => {
            query = query + `&userIds=${userId}`
            return query
        }, '') : '';

        const response = await apiClient.get<IChartTimes>(`click-up/times_chart?${projectQueryParam}${userQueryParam}`);
        return response.data;
    }

    async getWeekTimes(month: number, year: number, offset: number, positions: string[]): Promise<IWeekTime[]> {
        const positionsQueryParam = positions && positions.length ? positions.reduce((query, position) => {
            query = query + `&Positions=${position}`
            return query
        }, '') : '';

        const response = await apiClient.get<IWeekTime[]>(`click-up/week_times?month=${month}&year=${year}&offset=${offset}${positionsQueryParam}`);

        return response.data;
    }

    async getPositions(): Promise<any> {
        let response = await apiClient.get<any>(`click-up/positions`);
        return response.data;
    }

    async getSkills(): Promise<any> {
        let response = await apiClient.get<any>(`click-up/skills`);
        return response.data;
    }

    async saveRates(reportRates: IReportRate[]): Promise<void> {
        await apiClient.post('report/rates', reportRates);
    }

    async saveToGoogleSheets(report: IReportData[], sheetName: string, startDate: Date, endDate: Date, rateMembers: IReportRate[]): Promise<string> {
        let response = await apiClient.post<string>(`report/generate`, {
            reportData: report.map(item => {
                return {
                    taskId: item.taskId,
                    task: item.taskName,
                    userId: item.user.id,
                    user: item.userName,
                    role: item.userPosition,
                    billable: item.billable,
                    nonBillable: item.nonBillable,
                    status: item.status,
                    rate: item.userRate
                }
            }),
            sheetName: sheetName,
            datesRange: {
                startDate: startDate,
                endDate: endDate,
                offset: -startDate.getTimezoneOffset()
            },
            rateMembers: rateMembers.reduce((all, element) => {
                let itemReport = report.find(item => item.user.id == element.userId && item.projectId == element.projectId);
                if(!itemReport){
                    let item = all.find(item => item.userId == element.userId && item.projectId == element.projectId);
                    if (item) {
                        all = all.filter(i => i != item)
                    }
                }
                return all;
            }, rateMembers)
        });
        return response.data;
    }

    async getReport(reportId: string): Promise<IReportGenerate> {
        let response = await apiClient.get<IReportGenerate>(`report/${reportId}`);
        return response.data;
    }

    async getComments(startDate: Date, endDate: Date): Promise<IComment[]> {
        const response = await apiClient.get<IComment[]>(`comments/get-all?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
        return response.data;
    }

    async saveComment(input:  ICreateOrUpdateComment): Promise<void> {
        await apiClient.post('comments/create-or-update', input);
    }

    async deleteComment(id:  number): Promise<void> {
        await apiClient.get(`comments/delete/${id}`);
    }

    async saveProject(projects) {
        const response = await apiClient.post('/time-planning/create-or-update', projects);
        return response.data;
    }

    async removeProject(plans) {
        const response = await apiClient.post('/time-planning/remove', plans);
        return response.data;
    }

    async getAllProjects() {
        const response = await apiClient.get<IProject[]>('/click-up/projects');
        return response.data
    }

    async getTimesPlanning(params) {
        const response = await apiClient.get<ITimePlan[]>('/time-planning/get-all', { params });
        return response.data;
    }

}

export const apiService = new ApiService();
