
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { IMonth, MonthsArray } from "@/types/months";
import { IGrouppedSkills, ILevel } from "@/types/skill-filter"
import { IProject } from "@/types/api";
import { NavTab, SkillsFilter, MultiSelect } from "@/components";
import SwitcherComponent from "../SwitcherComponent/SwitcherComponent.vue";
import { SearchArrow, FilterSkills, FilterSkillsOpen } from "@/icons";
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

@Component({
  components: {
    NavTab,
    SwitcherComponent,
    SearchArrow,
    FilterSkills,
    FilterSkillsOpen,
    SkillsFilter,
    MultiSelect
  }
})

export default class TableSelect extends Vue {
  public skillSelectShow: boolean = false;
  public projectSelectShow: boolean = false;
  public selectedProjectsFilter:number[] = []

  public months: IMonth[] = MonthsArray;
  public currentYear: number = new Date().getFullYear();

  @Prop({ required: true })
  readonly skills!: IGrouppedSkills[] | [];

  @Prop({ required: true })
  readonly projects!: IProject[] | [];

  @Prop({ required: true })
  readonly plannedProjects!: string[] | [];

  @Prop({ required: true })
  readonly selectedProjects!: any;

  @Prop({ required: true })
  readonly selectedMonth!: number;

  @Prop({ required: true })
  readonly selectedYear!: number;

  @Prop({ required: true })
  readonly search!: string;

  @Prop({ required: true })
  readonly initialComponentsLoaded!: Boolean;

  get view() {
    return this.$store.state.timeTableView;
  }

  get currentUser() {
    return this.$store.state.currentUser;
  }

  get appDataLoading() {
    return this.$store.state.appDataLoading;
  }

  get monthName() {
    return this.months.filter((m) => m.id === this.selectedMonth)[0].name;
  }

  @Emit("selectProjects")
  changeProjects(value): void {
    return value;
  }

  @Emit("selectDateRange")
  changeDateRange(month): { year: number; month: number } {
    return { year: this.selectedYear, month: month + 1 };
  }

  @Emit("startSearch")
  handleSearch(event) {
    return event.target.value;
  }

  @Emit("selectDateRange")
  handleMonthChange(counter): { year: number; month: number } {
    const month = 1 + this.selectedMonth - counter;

    if (month < 1) {
      return { year: +this.selectedYear - 1, month: 12 };
    } else if (month > 12) {
      return { year: +this.selectedYear + 1, month: 1 };
    }
    return { year: this.selectedYear, month };
  }

  @Emit("selectMode")
  changeMode(value): void {
    return value;
  }

  openCloseSkillFilter(value: boolean): void {
    this.skillSelectShow = !value;
  }

  openCloseProjectSelect(value: boolean): void {
    this.projectSelectShow = !value;
  }

  onClickOutsideSkillFilter () {
    this.skillSelectShow = false;
  }

  onClickOutsideProjectSelect () {
    this.projectSelectShow = false;
  }  
}
