
import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { IProject } from "@/types/api";
import vClickOutside from 'v-click-outside'
import IconCheck from '@/components/Icons/IconCheck.vue'

Vue.use(vClickOutside)

@Component({
  components: {
    IconCheck
  }
})
export default class MultiSelect extends Vue {   
    public selected:number[] = []
    public search:string = ""
    @Prop({ required: true })
    readonly show!: boolean;

    @Prop({ required: true })
    readonly options!: IProject[] | [];    

    @Prop({ required: true })
    readonly selectedOptions!: IProject[];
 
    @Emit("openCloseSkillFilter")
    handleOpenCloseSkillFilter(value) {
      return value;
    }

    @Emit("selectProjects")
    changeProjects(value): void {
      return value;
    }

    get currentUser() {
      return this.$store.state.currentUser
    }

    get projects() {
      return this.options
        .filter(item => this.search === "" || item.name.toUpperCase().indexOf(this.search.toUpperCase()) !== -1)
    }

    get selectedName() {
      return this.selectedOptions.length
        ? this.selectedOptions.map((item) => item.name)
        : [] 
    }

    uselectValue(value) {
      this.options.filter(item => item.id != value)
    }

    get placeHolderForSearch() {
      return this.selectedName.some(x => x) ? "" : "Find a project"
    }

    clearSearch() {
      if(this.search !== "")
        this.search = ""
    }

    changeSelectProject(option) {
      this.clearSearch();
      this.handleOpenCloseSkillFilter(false);
      var options = this.selectedOptions;
      if(options.some((item) => item.id === option.id))
        options = options.filter((item) => item.id !== option.id)
      else
        options.push(option);
      this.changeProjects(options);
    }

    removeSelected(index) {      
      let selectedIndex = this.selectedOptions.findIndex((option) => option.id === this.selectedOptions[index].id);
      if (selectedIndex > -1) {
        this.changeProjects(this.selectedOptions.filter((option, index) => index != selectedIndex));
      }      
    }    

    openProjectsInfo() {
      this.$router.push({path: "/projects-info", query: { 'projects' : this.selectedOptions.map((item) => item.name) }});
    }
}

